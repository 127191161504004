(function () {
    'use strict';

    if($('.whooshkaa-widget-player').length) {
        var checkPodcastIframe = setInterval(function() {
            if ($('.whooshkaa-widget-player iframe').length) {
                $('.whooshkaa-widget-player iframe').attr('title',$('.whooshkaa-widget-player').attr('data-title'));
                clearInterval(checkPodcastIframe);
           }
        }, 100);
    }
}());