(function () {
    'use strict';

    /**
     * @module videoPlayer
     */
     var videoPlayer = {};

    /**
     * Initialise all videos on a particular page
     * 
     * @memberof module:video
     */
    videoPlayer.init = function() {
        var videos = document.querySelectorAll('.qhealth__video_player');
        videos.forEach((video) => {
            var videoContainer = $(video).find('.qhealth__video_player__iframe');
            var videoType = videoContainer.attr('data-type');

            if (videoType === 'youtube') {
                QH.videoPlayer.initYoutube(video);
            } else if (videoType === 'vimeo') {
                QH.videoPlayer.initVimeo(video);
            }
        });
    };
    
    /**
     * Initialise a given video from YouTube
     * 
     * @memberof module:video
     * 
     * @param {Node} video  The main Video container DOM element
     */
    videoPlayer.initYoutube = function(video) {
        var videoContainer = $(video).find('.qhealth__video_player__video');

        videoContainer.each(function( i ) {
            var iframe = $(videoContainer[i]).find('.qhealth__video_player__iframe');
            var videoId = iframe.attr('data-video-id');
            var playerId = iframe.find('.qhealth__video_player__player').attr('id');

            var player = new YT.Player(playerId, {
                height: "100%",
                width: "100%",
                videoId: videoId,
                events: {
                    'onReady': onPlayerReady
                }
            });
        });

        function onPlayerReady(event) {
            var iframe = event.target;
            var videoWrapper = iframe.i.parentElement.parentElement;
            var playButton = videoWrapper.querySelector('.qhealth__video_player__thumbnail');

            //videoWrapper.classList.remove('hidden');
            playButton.addEventListener('click',(e)=>{
                e.preventDefault();          
                
                //show video
                setTimeout( function(){
                    videoWrapper.classList.remove('not-ready');
                    iframe.playVideo();          
                }, 195);
            });
        }
    };

    /**
     * Initialise a given video from Vimeo
     * 
     * @memberof module:video
     * 
     * @param {Node} video  The main Video container DOM element
     */
    videoPlayer.initVimeo = function(video) {
        var videoContainer = $(video).find('.qhealth__video_player__iframe');
        var playButton = video.querySelector('.qhealth__video_player__thumbnail');

        playButton.addEventListener('click',(e)=>{
            e.preventDefault();                
            
            //show video and set url of iframe
            setTimeout( function(){
                videoContainer.removeClass('hidden');
                var video_url = videoContainer.data('source') + '?autoplay=1';
                videoContainer.find('iframe').prop('src', video_url) ;                   
            }, 195);
            
            //hide the thumbnail
            $(playButton).parent().fadeOut(200);
        });
    };

    /**
     * Load the YouTube IFrame Player API code asynchronously.
     * 
     * @memberof module:video
     */
    videoPlayer.loadIframeApi = function() {
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    $(document).ready(function() {
        QH.videoPlayer = videoPlayer;

        // Load YouTube IFrame API and then initialise videos when ready
        if (document.querySelectorAll('.qhealth__video_player').length){
            QH.videoPlayer.loadIframeApi();
        }
    });
    
    if (document.querySelectorAll('.qhealth__video_player').length){
        window.onYouTubeIframeAPIReady = function() {
            QH.videoPlayer.init();
        }
    }
    
}());