/**
 * @module adminForm
 */

import 'select2';
import 'select2/dist/css/select2.css';

/* Import TinyMCE */
import tinymce from 'tinymce';

/* Default icons are required for TinyMCE 5.3 or above */
import 'tinymce/icons/default';

/* A theme is also required */
import 'tinymce/themes/silver';

/* Import the skin */
import 'tinymce/skins/ui/oxide/skin.css';

/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/image';

/* jQuery Sortable */
import 'jquery-ui/ui/widgets/sortable';

(function () {

    var adminForm = {};
    adminForm.editing = false;
    
    /**
     * Get the latest document files from Matrix 
     * 
     * @memberof module:adminForm
     */
    adminForm.getFiles = function() {
        var fileBrowser = typeof site !== 'undefined' ? site.metadata.sfFileBrowser.value : ``;
        var url = window.origin === 'http://0.0.0.0:8080' ? 'https://qhscb.squiz.cloud' : window.origin;
        return new Promise((resolve, reject) => {
            $.ajax({
                type: 'GET',
                url:  url + '/_nocache/?a=' + fileBrowser,
                success: function(data) {
                    resolve(data);
                },
                error: function(error) {
                    reject(error)
                }
            })
        })
    }

    /**
     * Init the drag and drop field. 
     * 
     * @memberof module:adminForm
     */
    adminForm.initDragDrop = function(editor, assetid) {
        let dropArea = document.querySelector('.drag-drop');
                                
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            dropArea.addEventListener(eventName, preventDefaults, false);
            document.body.addEventListener(eventName, preventDefaults, false);
        });

        ['dragenter', 'dragover'].forEach(eventName => {
            dropArea.addEventListener(eventName, highlight, false);
        });
            
        ['dragleave', 'drop'].forEach(eventName => {
            dropArea.addEventListener(eventName, unhighlight, false);
        });

        dropArea.addEventListener('drop', handleDrop, false);

        function preventDefaults (e) {
            e.preventDefault();
            e.stopPropagation();
        }
          
        function highlight(e) {
            dropArea.classList.add('highlight');
        }
        
        function unhighlight(e) {
            dropArea.classList.remove('active');
        }

        function handleDrop(e) {
            var dt = e.dataTransfer;
            var files = dt.files;

            if (assetid) {
                adminForm.updateFile(files, editor, assetid);
            } else {
                adminForm.uploadFiles(files, editor);
            }
        }
    }

    /**
     * Upload files to Matrix with Asset Builder
     * 
     * @memberof module:adminForm
     */
    adminForm.uploadFiles = function(files, editor) {

        for (let i = 0; i < files.length; i++) {
            var progress = document.createElement('div');
            progress.classList.add("file-progress");
            progress.innerHTML = `<div class="file-progress__title">
                                    <div class="file-progress__left">
                                        <div class="file-progress__name">${files[i].name}</div> - <div class="file-progress__status">Uploading</div>
                                    </div>
                                    <div class="file-progress__percentage">0%</div>
                                </div>
                                <div class="file-progress-bar">
                                    <div class="file-progress-bar__filled"></div>
                                </div>
                                <div class="file-progress-action">
                                    <button class="insert-file tox-button">Add this file</button>
                                </div>`;
            
            $('.tox-form__group').append(progress);
            ajaxRequest(files[i], progress, editor);
        }

        function ajaxRequest(file, progress, editor) {
            var assetBuilder = typeof site !== 'undefined' ? site.metadata.sfAssetBuilder.value : ``;
            var actionUrl = window.origin === 'http://0.0.0.0:8080' ? 'https://qhscb.squiz.cloud/?a=' + assetBuilder : window.origin + '/?a=' + assetBuilder;
        
            var formData = new FormData();
            var file_type = getAssetType(file.type);

            formData.set('AB_' + assetBuilder + '_ASSET_BUILDER_ACTION', 'create');
            formData.set('AB_' + assetBuilder + '_ASSET_BUILDER_CREATE_TYPE', file_type);
            formData.set('asset_action', 'create');
            formData.set('asset_ei_screen', 'details');
            formData.set('sq_link_path', '');
            formData.set('sq_preview_url', '');
            formData.set('screen_header_save_button', '1');
            formData.set(file_type + '_0_269', '');
            formData.set(file_type + '_0', file);
            formData.set('sq_lock_release', '0');
            formData.set('sq_committing', '0');

            var xhr = new XMLHttpRequest();
            xhr.open("POST", actionUrl);

            xhr.onload = function () {
                if (this.readyState == 4 && this.status == 200) {
                    var response = JSON.parse(this.response);
                    $(progress).find('.insert-file').on('click',function(){
                        editor.insertContent(`<a href="${response.asset_url}" target="_blank">${response.asset_name}</a>`);
                        this.innerHTML = "Added";
                        this.disabled = true;
                    });

                    $(progress).addClass('file-complete');
                    $(progress).find('.file-progress__status').text('Complete');

                } else {
                    $(progress).addClass('file-error');
                    $(progress).find('.file-progress__status').text('Error!');
                }
            };

            xhr.upload.addEventListener("progress", function (evt) {
                if (evt.lengthComputable) {
                    var percentComplete = evt.loaded / evt.total;
                    var percent = Math.round(percentComplete * 100);
                    $(progress).find('.file-progress__percentage').text(percent + '%');
                    $(progress).find('.file-progress-bar__filled').css({
                        width: percent + '%'
                    });
                }
            }, false);

            xhr.send(formData);
        }

        function getAssetType(type) {
            var assetType = 'file';
            switch (true) {
                case (type.indexOf("pdf") != -1):
                    assetType = 'pdf_file'; break;
                case (type.indexOf("word") != -1):
                    assetType = 'word_doc'; break;
                case (type.indexOf("excel") != -1):
                    assetType = 'excel_doc'; break;
                case (type.indexOf("powerpoint") != -1):  
                    assetType = 'powerpoint_doc'; break;
                default:
                    break;
            }
            return assetType;
        }
    };

    /**
     * Update an existing file asset with JS API
     * 
     * @memberof module:adminForm
     */
    adminForm.updateFile = function(files, assetid, type) {

        // JS API
        var options = new Array();
        options['key'] = typeof site !== 'undefined' ? site.metadata.sfJSAPI.value : ``;
        var js_api = new Squiz_Matrix_API(options);

        function matchAssetType(file, type) {
            var match = false;
            switch (true) {
                case (type === 'pdf_file' && file.type.indexOf("pdf") != -1):
                    match = true; break;
                case (type === 'word_doc' && file.type.indexOf("word") != -1):
                    match = true; break;
                case (type === 'excel_doc' && file.type.indexOf("excel") != -1):
                    match = true; break;
                case (type === 'powerpoint_doc' && file.type.indexOf("powerpoint") != -1): 
                    match = true; break;
                case (type === 'file'):
                    match = true; break;
                default:
                    break;
            }
            return match;
        }

        if(files.length > 1) {
            alert("Multiple files were selected. Please select only one file.");
        } else if(!matchAssetType(files[0],type)){
            type = type.replaceAll('_',' ').replace('doc','document');
            alert("This asset only accepts " + type + ". You can upload your file as a new asset instead.");
        } else {
            var progress = document.createElement('div');
            progress.classList.add("file-progress");
            progress.innerHTML = `<div class="file-progress__title">
                                    <div class="file-progress__left">
                                        <div class="file-progress__name">${files[0].name}</div> - <div class="file-progress__status">Uploading</div>
                                    </div>
                                    <div class="file-progress__percentage"></div>
                                </div>`;
            
            $('.tox-form__group').append(progress);
            
            var fileContent = '';

            var reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = function () {
                fileContent = reader.result;
                js_api.acquireLock({
                    "asset_id": assetid,
                    "screen_name": "attributes",
                    "dependants_only":0,
                    "force_acquire": 1,
                    "dataCallback": updateFileAsset
                });
            };
            reader.onerror = function (error) {
                $(progress).addClass('file-error');
                $(progress).find('.file-progress__status').text('Error!');
            };

            function updateFileAsset(response) {
                console.log(response);
                js_api.updateFileAssetContent({
                    "asset_id": assetid,
                    "content": fileContent,
                    "dataCallback": updateCallback
                });
            }

            function updateCallback(response) {
                console.log(response);
                if(response.success){
                    $(progress).addClass('file-complete');
                    $(progress).find('.file-progress__status').text('Complete');
                } else {
                    $(progress).addClass('file-error');
                    $(progress).find('.file-progress__status').text('Error!');
                }

                js_api.releaseLock({
                    "asset_id": assetid,
                    "screen_name": "attributes",
                    "dataCallback": released
                }) 
            }

            function released(response) {
                console.log(response);
            }
        }
    };

    /**
     * Chnage file asset's status to Archive with JS API
     * 
     * @memberof module:adminForm
     */
    adminForm.deleteFile = function(dialog, assetid, successDialog, errorDialog) {
        
        // JS API
        var options = new Array();
        options['key'] = typeof site !== 'undefined' ? site.metadata.sfJSAPI.value : ``;
        var js_api = new Squiz_Matrix_API(options);

        js_api.acquireLock({
            "asset_id": assetid,
            "screen_name": "attributes",
            "dependants_only":0,
            "force_acquire": 1,
            "dataCallback": assetStatus
        });

        function assetStatus (response) {
            console.log(response);
            if(response[0].indexOf('locks are now acquired') != -1){
                js_api.setAssetStatus({
                    "asset_id": assetid,
                    "status": 1,
                    "cascade": 0,
                    "workflow_stream": "",
                    "userlog": "",
                    "dataCallback": deleteCallback
                });
            } else {
                dialog.redial(errorDialog);
            }
        }
        
        function deleteCallback(response) {
            console.log(response);
            if(response[0].indexOf('success') != -1){
                js_api.trashAsset({
                    "asset_ids":[
                        assetid
                    ],
                    "dataCallback": trashed
                });
                dialog.redial(successDialog);
            } else {
                dialog.redial(errorDialog);
            }
        }

        function trashed(response) {
            console.log(response);
        }
    };

    /**
     * Init the Service Finder admin form. This includes adding
     * event listeners, as well as initializing the tinyMCE Plugin
     * on Appropriate TextAreas 
     * 
     * @memberof module:adminForm
     */
    adminForm.init = function() {
        // Main form element
        var $form = $('.qhealth__service_finder_admin_form form');

        // Init WYWSIWYG
        var tinymceStyles = $form.data('tinymce-styles');

        tinymce.init({
            selector: '.tinymce',
            menubar: false,
            plugins: 'advlist code link lists table paste nonbreaking image',
            toolbar: 'undo redo | paste | styleselect | bold italic | bullist numlist | table | link code fileupload | nonbreaking | help',
            content_css: tinymceStyles,
            invalid_styles: { 
                'table': 'width height', 
                'tr' : 'width height',
                'th' : 'width height',
                'td' : 'width height'
            },
            style_formats: [
                {title: 'Paragraph', format: 'p'},
                {title: 'Header 1', format: 'h1'},
                {title: 'Header 2', format: 'h2'},
                {title: 'Header 3', format: 'h3'},
                {title: 'Header 4', format: 'h4'},
                {title: 'Header 5', format: 'h5'},
                {title: 'Header 6', format: 'h6'},
                {title: 'Blockquote', format: 'blockquote'},
                {title: 'Div', format: 'div'},
                {title: 'Pre', format: 'pre'},
                {title: 'Abstract', selector: 'p', classes: 'qhealth__abstract'},
                {title: 'Table caption', selector: 'caption', classes: 'qhealth__table__caption'},
                {title: 'Table striped', selector: 'table', classes: 'qhealth__table--striped'}
            ],
            extended_valid_elements: [
                'table[class=qhealth__table]',
                'tbody[class=qhealth__table__body]',
                'tr[class=qhealth__table__row]',
                'thead[class=qhealth__table__head]',
                'th[class=qhealth__table__header]',
                'td[class=qhealth__table__cell]'
            ],
            paste_preprocess: function(plugin, args) {
                console.log(args.content);
                // args.content += ' preprocess';
            },
            paste_postprocess: function(plugin, args) {
                console.log(args);

                // select all elements. Or just specific ones.
                var elements = args.node.getElementsByTagName("*");

                // use dataset api to delete all properties.
                for (var i = 0; i < elements.length; i++) {
                    for (var prop in elements[i].dataset) {
                        delete elements[i].dataset[prop];
                    }
                }
            },
            setup: function(editor) {
                editor.ui.registry.addIcon('addfile', '<svg width="24" height="24" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g><path d="M14.414,3l-7.414,0c-1.103,0 -2,0.898 -2,2l0,14c0,1.103 0.897,2 2,2l10,0c1.103,0 2,-0.897 2,-2l0,-11.414l-4.586,-4.586Zm2.587,16l-10.001,0l-0,-14l6,0l-0,4l4,0l0.001,10Z" style="fill-rule:nonzero;"/><path d="M10.971,13.099l-2.205,-0c-0.374,-0 -0.677,0.304 -0.677,0.677l-0,0.55c-0,0.374 0.303,0.678 0.677,0.678l2.204,-0l-0.002,2.258c-0,0.374 0.303,0.678 0.677,0.678l0.55,-0c0.373,0.001 0.677,-0.303 0.678,-0.677l0.001,-2.259l2.317,-0c0.374,-0 0.678,-0.304 0.678,-0.678l-0,-0.55c-0,-0.373 -0.304,-0.677 -0.678,-0.677l-2.315,-0l0.002,-2.261c-0,-0.374 -0.303,-0.678 -0.677,-0.678l-0.55,-0c-0.374,-0.001 -0.678,0.303 -0.678,0.677l-0.002,2.262Z"/></g></svg>' );

                editor.on('change', function(e) {
                    editor.save();
                    $('#' + e.target.id).valid();
                });
                
                // File upload functionality 
                editor.ui.registry.addButton('fileupload', {
                    icon: 'addfile',
                    tooltip: 'Add a file',
                    onAction: function (_) {
                        $.fn.dataTable.moment('DD MMM YYYY h:mma');
                        var beforeRedial = '';
                        var dialogConfig = {
                            title: 'Add a file',
                            body: {
                                type: 'tabpanel',
                                tabs: [
                                    {
                                        name: 'browser',
                                        title: 'Browse',
                                        items: [{
                                            type: 'htmlpanel',
                                            name: 'browser-table',
                                            html: `<div class="loading"><div class="loader">Loading...</div></div>`
                                        }]
                                    },
                                    {
                                        name: 'upload',
                                        title: 'Upload',
                                        items: [{
                                            type: 'htmlpanel',
                                            name: 'drag-drop',
                                            html: `<div class="tox-dropzone drag-drop">
                                                        <p>Drop a file here</p>
                                                        <label class="drag-drop__label tox-button--secondary" for="upload">Browse for a file</label>
                                                        <input type="file" id="upload" class="drag-drop__input" multiple hidden>
                                                    </div>`
                                        }]
                                    }
                                ]
                            },
                            buttons: [
                                {
                                    type: 'cancel',
                                    text: 'Close'
                                }
                            ],
                            size: 'medium',
                            onTabChange: function (dialogApi, details) {
                                if (details.oldTabName || beforeRedial == 'update') {
                                    if (details.newTabName === 'browser') {
                                        adminForm.getFiles()
                                        .then((data) => {
                                            dialogConfig.body.tabs[0].items[0].html = data;
                                            beforeRedial = 'browser';
                                            dialogApi.redial(dialogConfig);
                                            window.onclick = function(event) {
                                                var dropdowns = document.querySelectorAll('.file-browser-actions-dropdown');
                                                for (var i = 0; i < dropdowns.length; i++) {
                                                    if (dropdowns[i].classList.contains('show')) {
                                                        dropdowns[i].classList.remove('show');
                                                    }
                                                }

                                                if (event.target.matches('.file-browser-actions__button')) {
                                                    event.target.nextElementSibling.classList.add('show')
                                                }

                                                if (event.target.matches('.addFile')) {
                                                    editor.insertContent(`<a href="${event.target.dataset.asseturl}" target="_blank">${event.target.dataset.assetname}</a>`);
                                                }

                                                if (event.target.matches('.updateFile')) {
                                                    dialog.redial(updateDialog);
                                                    adminForm.initDragDrop(editor, event.target.dataset.assetid);
                                                    document.querySelector('.drag-drop__input').addEventListener("change", function(e){
                                                        adminForm.updateFile(this.files, event.target.dataset.assetid, event.target.dataset.type);
                                                    }, false);
                                                }

                                                if (event.target.matches('.deleteFile')) {
                                                    deleteDialog.body.items[0].text = `Are you sure you want to delete <b>${event.target.dataset.assetname}</b>? This will break any existing links to this file.`;
                                                    dialog.redial(deleteDialog);
                                                    document.querySelector('.delete-confirm').addEventListener("click", function(e){
                                                        adminForm.deleteFile(dialog, event.target.dataset.assetid, successDialog, errorDialog);
                                                    }, false);
                                                }
                                            }
                                        })
                                        .catch((error) => {
                                            dialogConfig.body.tabs[0].items[0].html = `<p>Something went wrong. Please refresh the page and try again.</p>`;
                                            beforeRedial = 'browser';
                                            dialog.redial(dialogConfig);
                                        });
                                    } else {
                                        adminForm.initDragDrop(editor);
                                        document.querySelector('.drag-drop__input').addEventListener("change", function(e){
                                            adminForm.uploadFiles(this.files, editor);
                                        }, false);
                                    }
                                }

                                if($('.file-browser').length) {
                                    $('.file-browser').DataTable({
                                        "dom": 't',
                                        "columnDefs": [
                                            { orderable: false, targets: 0 },
                                            { orderable: true, targets: 2, type: "file-size" },
                                            { orderable: false, targets: 4 }
                                        ],
                                        "order": []
                                    });
                                }
                            } 
                        };

                        var dialog = tinymce.activeEditor.windowManager.open(dialogConfig);

                        var updateDialog = {
                            title: 'Update a file',
                            body: {
                                type: 'panel',
                                items: [{
                                    type: 'htmlpanel',
                                    name: 'drag-drop',
                                    html: `<div class="tox-dropzone drag-drop">
                                                <p>Drop a file here</p>
                                                <label class="drag-drop__label tox-button--secondary" for="upload">Browse for a file</label>
                                                <input type="file" id="upload" class="drag-drop__input" hidden>
                                            </div>`
                                }]
                            },
                            buttons: [
                                {
                                    type: 'submit',
                                    text: 'Back'
                                }
                            ],
                            size: 'medium',
                            onSubmit: (dialogApi) => {
                                beforeRedial = 'update';
                                dialogApi.redial(dialogConfig);
                            }
                        };

                        var deleteDialog = {
                            title: 'Delete a file',
                            body: {
                                type: 'panel',
                                items: [{
                                    type: 'alertbanner',
                                    level: 'warn',
                                    text: '',
                                    icon: 'warning'
                                },{
                                    type: 'htmlpanel',
                                    name: 'continue',
                                    html: `<div class="tox-button-group"><button class="tox-button delete-confirm">Continue</button></div>`
                                }]
                            },
                            buttons: [
                                {
                                    type: 'submit',
                                    text: 'Back'
                                }
                            ],
                            size: 'normal',
                            onSubmit: (dialogApi) => {
                                beforeRedial = 'update';
                                dialogApi.redial(dialogConfig);
                            }
                        };

                        var successDialog = {
                            title: 'Delete a file',
                            body: {
                                type: 'panel',
                                items: [{
                                    type: 'alertbanner',
                                    level: 'success',
                                    icon: 'checkmark',
                                    text: 'The file has been deleted successfully.'
                                }]
                            },
                            buttons: [
                                {
                                    type: 'submit',
                                    text: 'Back'
                                }
                            ],
                            size: 'normal',
                            onSubmit: (dialogApi) => {
                                beforeRedial = 'update';
                                dialogApi.redial(dialogConfig);
                            }
                        };
                
                        var errorDialog = {
                            title: 'Delete a file',
                            body: {
                                type: 'panel',
                                items: [{
                                    type: 'alertbanner',
                                    level: 'error',
                                    icon: 'close',
                                    text: 'Something went wrong. Please try again later.'
                                }]
                            },
                            buttons: [
                                {
                                    type: 'submit',
                                    text: 'Back'
                                }
                            ],
                            size: 'normal',
                            onSubmit: (dialogApi) => {
                                beforeRedial = 'update';
                                dialogApi.redial(dialogConfig);
                            }
                        };

                        adminForm.getFiles()
                        .then((data) => {
                            dialogConfig.body.tabs[0].items[0].html = data;
                            dialog.redial(dialogConfig);
                            window.onclick = function(event) {
                                var dropdowns = document.querySelectorAll('.file-browser-actions-dropdown');
                                for (var i = 0; i < dropdowns.length; i++) {
                                    if (dropdowns[i].classList.contains('show')) {
                                        dropdowns[i].classList.remove('show');
                                    }
                                }
                                if (event.target.matches('.file-browser-actions__button')) {
                                    event.target.nextElementSibling.classList.add('show')
                                }

                                if (event.target.matches('.addFile')) {
                                    editor.insertContent(`<a href="${event.target.dataset.asseturl}" target="_blank">${event.target.dataset.assetname}</a>`);
                                }

                                if (event.target.matches('.updateFile')) {
                                    dialog.redial(updateDialog);
                                    adminForm.initDragDrop(editor, event.target.dataset.assetid);
                                    document.querySelector('.drag-drop__input').addEventListener("change", function(e){
                                        adminForm.updateFile(this.files, event.target.dataset.assetid, event.target.dataset.type);
                                    }, false);
                                }

                                if (event.target.matches('.deleteFile')) {
                                    deleteDialog.body.items[0].text = `Are you sure you want to delete <b>${event.target.dataset.assetname}</b>? This will break any existing links to this file.`;
                                    dialog.redial(deleteDialog);
                                    document.querySelector('.delete-confirm').addEventListener("click", function(e){
                                        adminForm.deleteFile(dialog, event.target.dataset.assetid, successDialog, errorDialog);
                                    }, false);
                                }
                            }
                        })
                        .catch((error) => {
                            dialogConfig.body.tabs[0].items[0].html = `<p>Something went wrong. Please refresh the page and try again.</p>`;
                            dialog.redial(dialogConfig);
                        });
                    }
                });
            }
        });

        // Init jQuery validate
        QH.forms.init();

        // Get auth token for JWT
        serviceFinderData.jwt.getAuthToken();

        // Update 'editing' var
        var formType = $form.data('type');
        if (formType === 'edit') {
            adminForm.editing = true;
        }

        // Add extra validation
        adminForm.addValidationRules();

        // Populate from query param
        adminForm.populateFromParams();

        // Init Select2 for fields with more than 2 options
        $form.find('select').each(function() {
            var $select = $(this);
            if ($select.children().length > 2) {
                $select.select2();
            }
        });

        // Init autopopulate
        adminForm.addAutoPopulateListeners();

        // Init Facility Autopopulation for location fields (address)
        adminForm.autoPopulateFacilityAddress();

        // Clone document (service-locations for now)
        adminForm.cloneDocument();

        $form.find('select').each(function() {
            var $select = $(this);

            if($select.find('option').length === 1) {
                $select.trigger('change');
            }
        });

        // Handle Submit
        if (formType === 'top-services') {
            $form.on('submit', adminForm.addTopService);
        } else {
            $form.on('submit', adminForm.handleSubmit);
        }
        
    };

    /**
     * Add custom jQuery validate rules to the form
     * 
     * @memberof module:adminForm
     */
    adminForm.addValidationRules = function() {
        
        var $form = $('.qhealth__service_finder_admin_form form');
        var collection = $form.data('collection').replace(/_/g, '-');
        
        // Add Unique Web Path validation (with jQuery validate)
        var collectionUrl = serviceFinderData.baseURL + '/' + collection;
        $('#sf-field__web_path').rules("add", {
            remote: {
                url: collectionUrl,
                type: 'get',
                data: {
                    filters: function() {
                        return 'document.web_path===' + $('#sf-field__web_path').val();
                    }
                },
                dataFilter: function(data) {
                    var field = $('#sf-field__web_path');
                    var newValue = field.val();
                    var currentValue = field.data('current');
                    
                    if (newValue === currentValue) {
                        return true;
                    }

                    var response = JSON.parse(data);
                    
                    if (response.length > 0) {
                        return false;
                    }
                    
                    return true;
                }
            },
            nospaces: true,
            messages: {
                required: $.validator.format("Please enter a unique Web Path"),
                remote: $.validator.format("Web Path already taken"),
                nospaces: $.validator.format("Web Path must have no spaces")
            }
        });

        // Check for unique ID
        $('#sf-field__id').rules("add", {
            remote: {
                url: collectionUrl,
                type: 'get',
                data: {
                    filters: function() {
                        return 'document.id===' + $('#sf-field__id').val();
                    }
                },
                dataFilter: function(data) {
                    var field = $('#sf-field__id');
                    var newValue = field.val();
                    var currentValue = field.data('current');
                    
                    if (newValue === currentValue) {
                        return true;
                    }

                    var response = JSON.parse(data);
                    
                    if (response.length > 0) {
                        return false;
                    }
                    
                    return true;
                }
            },
            nospaces: true,
            messages: {
                required: $.validator.format("Please enter a unique ID"),
                remote: $.validator.format("ID already taken"),
                nospaces: $.validator.format("ID must have no spaces")
            }
        });

        // Check for existing top service (with jQuery validate)
        if ($('#sf-field__top-service').length > 0) {
            var topServiceUrl = serviceFinderData.baseURL + '/services';
            $('#sf-field__top-service').rules("add", {
                remote: {
                    url: topServiceUrl,
                    type: 'get',
                    data: {
                        filters: function() {
                            return 'document.top_in_' + collection + '@>' + $('#sf-field__top-service').data('document');
                        }
                    },
                    dataFilter: function(data) {
                        var response = JSON.parse(data);
                        var selectedValue = $('#sf-field__top-service').val();
                        console.log(response);
                        if (response.length > 0) {
                            var matches = response.filter((service) => {
                                return service.id === selectedValue;
                            });
                            if (matches.length > 0) return false;
                        }
                        return true;
                    }
                },
                messages: {
                    required: $.validator.format("Please choose a service"),
                    remote: $.validator.format("This is already a top service")
                }
            });

        }
        

        // Check for existing hhs service (with jQuery validate)
        if ($('form[data-collection="hhs-services"]').length > 0) {
            var checkHhsServiceUrl = serviceFinderData.baseURL + '/hhs-services';
            $('#sf-field__hhs_id').rules("add", {
                remote: {
                    url: checkHhsServiceUrl,
                    type: 'get',
                    data: {
                        filters: function() {
                            return 'document.service_id===' + $('#sf-field__service_id').val() + ';document.hhs_id===' + $('#sf-field__hhs_id').val();
                        }
                    },
                    dataFilter: function(data) {
                        var response = JSON.parse(data);
                        console.log(response);
                        if (response.length > 0) {
                            return false;
                        }
                        return true;
                    }
                },
                messages: {
                    required: $.validator.format("Please choose a HHS"),
                    remote: $.validator.format("This HHS already has content for the specified Service"),
                }
            });
        }

        // Check for existing service location (with jQuery validate)
        if ($('form[data-collection="service-locations"]').length > 0) {
            var checkServiceLocationURL = serviceFinderData.baseURL + '/service-locations';
            $('#sf-field__service_id').rules("add", {
                remote: {
                    url: checkServiceLocationURL,
                    type: 'get',
                    data: {
                        filters: function() {
                            return 'document.service_id===' + $('#sf-field__service_id').val() + ';document.facility_id===' + $('#sf-field__facility_id').val();
                        }
                    },
                    dataFilter: function(data) {
                        var response = JSON.parse(data);
                        console.log(response);
                        if (response.length > 0) {
                            return false;
                        }
                        return true;
                    }
                },
                messages: {
                    required: $.validator.format("Please choose a service"),
                    remote: $.validator.format("A service location for this service/facility already exists"),
                }
            });

            $('#sf-field__facility_id').on('change', function() {
                $('#sf-field__service_id').valid();
            });
        }
    };
    

    /**
     * Add event listeners for autopopulated fields
     * 
     * @memberof module:adminForm
     */
    adminForm.addAutoPopulateListeners = function() {

        // For each input 
        $('input[data-populated-by]').each(function() {
            var $targetInput = $(this);
            var populateType = $targetInput.data('populate-type');

            // Only add listener if not disabled
            if ($targetInput.attr('disabled') !== 'disabled') {
                var dependantInputKeys = $targetInput.data('populated-by').split(',');
                
                // Add data attributes to dependant fields
                dependantInputKeys.forEach((key, index) => {
                    $('#sf-field__' + key).attr('data-populate', $targetInput.attr('id'));

                    if (populateType === 'concat') {
                        $('#sf-field__' + key).attr('data-populate-index', index);
                    }
                });
                
                // Add listeners to dependant fields
                var $dependantInputs = $('[data-populate="' + $targetInput.attr('id') + '"]');
                
                switch (populateType) {
                    case 'url_transform':
                        $dependantInputs.on('keyup', adminForm.autoPopulate);
                        break;
                    default:
                        $dependantInputs.on('change', adminForm.autoPopulate);
                }

            }
        });
    }

    /**
     * Automatically populate value of target field based
     * on values set in dependant field(s)
     * 
     * @memberof module:adminForm
     */
    adminForm.autoPopulate = function() {
        var $field = $(this);
        var targetId = $field.data('populate');
        var $targetInput = $('#' + targetId);
        var populateType = $targetInput.data('populate-type');

        // Update target value depending on type
        switch (populateType) {

            case 'url_transform':
                $targetInput.val(adminForm.kebabFormat($field.val()));
                break;

            case 'concat':
                var useFriendlyNames = $targetInput.data('populate-friendly') === true ? true : false;
                var $dependantFields = $('[data-populate="' + targetId + '"]');
                var value = ""
                
                for (var i = 0; i < $dependantFields.length; i++) {
                    if (i > 0) {
                        if (useFriendlyNames) {
                            value += " - ";
                        } else {
                            value += "-";
                        }
                    }
                    var $dependantField = $('[data-populate="' + targetId + '"][data-populate-index="' + i + '"');
                    if (useFriendlyNames) {
                        var partialValue = $dependantField.children(':selected').data('friendly')
                        value += (partialValue ? partialValue : '');
                    } else {
                        value += $dependantField.val();
                    }
                }

                $targetInput.val(value);
                break;

            case 'selected_text':
                $targetInput.val($field.children(':selected').data('friendly'));
                break;
        }

        // Check if new value is valid
        $targetInput.valid();

        // If target field is also dependant, trigger the 'keyup' event
        if (typeof($targetInput.data('populate')) !== 'undefined') {
            $targetInput.trigger('keyup');
        }
    };
/**
     * Automatically populate address fields for service location based on selected facility
     * 
     * @memberof module:adminForm
     */
    adminForm.autoPopulateFacilityAddress = function() {
        if ($('form[data-collection="service-locations"]').length > 0) {
            var $facilityField = $('#sf-field__facility_id');
            var $streetBuildingField = $('#sf-field__building');
            var $streetAddressField = $('#sf-field__physical_address_street');
            var $suburbField = $('#sf-field__physical_address_suburb');
            var $postcodeField = $('#sf-field__physical_address_postcode');
            var locationDetailsField = tinymce.get("sf-field__location_details");
            var $latitudeField = $('#sf-field__latitude');
            var $longitudeField = $('#sf-field__longitude');
            var phoneNumberField = tinymce.get("sf-field__phone_number");
            var faxNumberField = tinymce.get("sf-field__fax_number");
            var emailField = tinymce.get("sf-field__email_address");

            $facilityField.on('change', function() {
                var dsFacilityUrl = serviceFinderData.baseURL + '/facilities/' + $facilityField.val(); 
                
                fetch(dsFacilityUrl)
                    .then(response => response.json())
                    .then(data => {
                        for(var row in data) {
                            switch (row) {
                                case 'building':
                                    $streetBuildingField.val(data[row]);
                                    break;
                                case 'physical_address_street':
                                    $streetAddressField.val(data[row]);
                                    break;
                                case 'physical_address_suburb':
                                    $suburbField.val(data[row]);
                                    break;
                                case 'physical_address_postcode':
                                    $postcodeField .val(data[row]);
                                    break;
                                case 'location_details':
                                    locationDetailsField.execCommand('mceSetContent', false, data[row]);
                                    break;
                                case 'latitude':
                                    $latitudeField.val(data[row]);
                                    break;
                                case 'longitude':
                                    $longitudeField.val(data[row]);
                                    break;
                                case 'phone_number':
                                    phoneNumberField.execCommand('mceSetContent', false, data[row]);
                                    break;
                                case 'fax_number':
                                    faxNumberField.execCommand('mceSetContent', false, data[row]);
                                    break;
                                case 'email_address':
                                    emailField.execCommand('mceSetContent', false, data[row]);
                                    break;
                            } 
                        }

                        $facilityField.focus();
                    });
            });
        }
    }

    /**
     * Clones the selected document
     * 
     * @memberof module:adminForm
     */
    adminForm.cloneDocument = function() {
        var collectionToCloneFrom = QH.utils.getParamaterByName("collection");
        var documentToClone = QH.utils.getParamaterByName("clone");
        var $loader = $('.qhealth__service_finder_admin_form');
        var $form = $('.qhealth__form--validate');

        if(collectionToCloneFrom && documentToClone) {
            var dsDocumentData = `${serviceFinderData.baseURL}/${collectionToCloneFrom}/${documentToClone}`; 

            $loader.addClass('loading');

            setTimeout(function() {
                fetch(dsDocumentData)
                    .then(response => {
                        if (response.status === 200) {
                            return response;
                        } else {
                            $loader.removeClass('loading');
                            var errorEl = $('#sf-admin-form__error');

                            errorEl.text(`Cloning of ${collectionToCloneFrom.replace(/-/g, ' ').slice(0, -1).replace(/ie/g, 'y')} failed, document not found.`);
                            errorEl.addClass('active');

                            throw new Error(response.statusText);
                        }
                    })
                    .then(response => response.json())
                    .then(data => { 
                        for (var key in data) {
                            var value = data[key];

                            if(value !== null) {
                                var $sfField = $(`#sf-field__${key}`);

                                if($sfField.hasClass('tinymce')) {
                                    var mceField = tinymce.get(`sf-field__${key}`);
                                    mceField.execCommand('mceSetContent', false, value);
                                } else {
                                    $sfField.val(value);
                                }

                                if($sfField.is('select')) {
                                    $sfField.trigger('change');
                                }

                                $form.valid();
                            }
                        }

                        $loader.removeClass('loading');
                    })
                    .catch((error) => {
                        console.log(error);
                        $('#sf-admin-form__error').addClass('active');
                    });
            }, 400);
        }
    }

    /**
     * Returns kebab-ified version of string
     * 
     * @memberof module:adminForm
     * 
     * @param {string} str 
     * @returns {string}
     * 
     * @example
     * // returns 'service-123'
     * adminForm.kebabFormat('Service 123);
     */
    adminForm.kebabFormat = function(str) {
        return str
        .replace(/([a-z])([A-Z])/g, "$1-$2")
        .replace(/[^a-zA-Z0-9\s_]/g, "")
        .replace(/[\s_]+/g, '-')
        .toLowerCase();
    };

    /**
     * Handle submit of Service Finder admin form, and
     * add new item to Datastore
     * 
     * @memberof module:adminForm
     * 
     * @param {Document.event} e 
     */
    adminForm.handleSubmit = function(e) {
        e.preventDefault();
        var $form = $(this);
        adminForm.toggleError(false);

        // Save all WYSIWYGs
        $('.tinymce').each(function() {
            var id = $(this).attr('id');
            tinymce.get(id).save();   
        });

        // Check validity
        var isValid = $form.valid();

        // Only proceed if all form input is valid
        if (isValid) {
            var collection = $form.data('collection').replace(/_/g, '-');
            var parent = $form.closest('.qhealth__service_finder_admin_form');
            parent.addClass('loading');
            
            // Get document data from form
            var docData = {};
            var docId;

            $form.find('input[id^=sf-field__], textarea[id^=sf-field__], select[id^=sf-field__]').each(function() {
                var type = $(this).attr('name').split('|')[0];
                var key = $(this).attr('name').split('|')[1];
                var value = $(this).val();

                switch (type) {
                    case 'wysiwyg':
                        var value = tinymce.get('sf-field__' + key).getContent();
                        docData[key] = value;
                        break;
                    case 'number':
                        docData[key] = Number(value);
                        break;
                    default:
                        docData[key] = value;
                }

                if(key === 'id') {
                    docId = value;
                }
            });

            // Get data from JWT
            docData.updatedBy = serviceFinderData.jwt.getPayload().fullName;
            docData.updatedDate = Date.now();

            // If editing, update the document
            if (adminForm.editing) {
                var documentId = $form.data('document');
                serviceFinderData.collection(collection).doc(documentId).update(docData).then((editedDoc) => {
                    console.log('Doc updated');
                    adminForm.redirectToDocument($form, editedDoc.id);
                }).catch((error) => {
                    console.log('Failed to update document');
                    console.error(error);
                    adminForm.toggleError(true);
                    parent.removeClass('loading');
                });
            
            // If creating, add new document
            } else {

                if(typeof docId !== 'undefined') {
                    serviceFinderData.collection(collection).add(docData, docId).then((newDoc) => {
                        console.log('Doc added');
                        adminForm.redirectToDocument($form, newDoc.id);
                    }).catch((error) => {
                        console.log('Failed to add document');
                        console.error(error);
                        adminForm.toggleError(true);
                        parent.removeClass('loading');
                    });
                } else {
                    serviceFinderData.collection(collection).add(docData).then((newDoc) => {
                        console.log('Doc added');
                        adminForm.redirectToDocument($form, newDoc.id);
                    }).catch((error) => {
                        console.log('Failed to add document');
                        console.error(error);
                        adminForm.toggleError(true);
                        parent.removeClass('loading');
                    });
                }
            }
        }
    };

    /**
     * Toggle the main admin form error
     * 
     * @memberof module:adminForm
     * 
     * @param {boolean} showError 
     */
    adminForm.toggleError = function(showError) {
        if (showError) {
            $('#sf-admin-form__error').addClass('active');
            $('#sf-admin-form__error').attr('tabindex','0');
            $('#sf-admin-form__error').focus();
        } else {
            $('#sf-admin-form__error').removeClass('active');
            $('#sf-admin-form__error').attr('tabindex','-1');
        }
    }

    /**
     * Redirect to new/edited document
     * 
     * @memberof module:adminForm
     * 
     * @param {jQuery} $form 
     * @param {string} docId 
     */
    adminForm.redirectToDocument = function($form, docId) {
        var collection = $form.data('collection').replace(/_/g, '-');
        var detailsURL = $form.attr('action') + '?collection=' + collection + '&document=' + docId;
        console.log('redirecting to ' + detailsURL);
        window.location = detailsURL;
    }

    /**
     * Handle addition of new top service
     * 
     * @memberof module:adminForm
     * 
     * @param {Document.event} e 
     */
    adminForm.addTopService = function(e) {
        e.preventDefault();
        var $form = $(this);
        var isValid = $form.valid();

        if (isValid) {
            console.log('adding top service');
            var parent = $form.closest('.qhealth__service_finder_admin_form');
            var collection = $form.data('collection').replace(/_/g, '-');
            var document = $('#sf-field__top-service').data('document');
            var selectedService = $('#sf-field__top-service').val();
            parent.addClass('loading');

            serviceFinderData.jwt.getAuthToken();

            serviceFinderData.collection('services').doc(selectedService).get().then((service) => {
                service['top_in_' + collection].push(document);
                service.updatedBy = serviceFinderData.jwt.getPayload().fullName;
                service.updatedDate = Date.now();
                console.log('updated service', service);

                serviceFinderData.collection('services').doc(selectedService).update(service).then(() => {
                    parent.removeClass('loading');
                    parent.html('<p>Top service added</p>');
                    console.log('Top service added');
                })
            });
        }
    };

    /**
     * Populate fields based on query params
     * Eg. ?service_id=service-1 will populate 
     * #sf-field__service-id with "service-1"
     * 
     * @memberof module:adminForm
     */
    adminForm.populateFromParams = function() {
        var search = window.location.search.substring(1);
        var params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
        for (var key in params) {
            var value = params[key];
            $('#sf-field__' + key).val(value);
        }
    };

    // Make admin form public
    QH.adminForm = adminForm;

    // Init Admin Form
    $(document).ready(function() {
        if ($('.qhealth__service_finder_admin_form form').length > 0) {
            QH.adminForm.init();
        }
    });
    
}());