(function (QH) {
    'use strict';
    
    /**
     * @module locationAllowModal
    */
    var locationAllowModal = {};

    /**
     * Initialise the modal for setting location permissions
     * 
     * @memberof module:locationAllowModal
    */
    locationAllowModal.init = function() {
        var $locationModal = $("#modal--allow-geolocate");
    
        if($locationModal.length) {
            // $locationModal.addClass('active');
            QH.Modal.open($locationModal);

            var $allowCurrentLocationBtn = $('[data-action="allow-location"]');
            var $denyCurrentLocationBtn = $('[data-action="deny-location"]')

            $allowCurrentLocationBtn.on('click', function() {
                var errorCallback = function() {
                    $allowCurrentLocationBtn.replaceWith('<p class="qhealth__input--error">Unable to locate</p>');
                }
                QH.utils.geolocateUser(true, errorCallback);
            });

            if($denyCurrentLocationBtn.length > 0) {
                $denyCurrentLocationBtn.on('click', function(e) {
                    QH.utils.setLocalStorage("deny-location", true);
                });
            }
        }
    };

    var QH = QH ? QH : {};
    QH.locationAllowModal = locationAllowModal;
    window.QH = QH;

    $(function() {
        if((!QH.utils.getLocalStorage('qh_user_location') || QH.utils.getLocalStorage('qh_user_location') === null) && !QH.utils.getLocalStorage('deny-location')) {
            setTimeout(() => QH.locationAllowModal.init(), 1000);
        }
    });
    
}(window.QH));