import { map } from "jquery";

(function () {
    'use strict';

    /**
     * @module facilityMap
     */
    var facilityMap = {};

    /**
     * Initialise all embedded facility maps on a particular page
     * 
     * @memberof module:facilityMap
     */
    facilityMap.init = function() {
        var mapEmbedFacilities = QH.utils.listToArray(document.querySelectorAll('.js-map-embed-facility'));

        setTimeout(function() {
            mapEmbedFacilities.forEach(function (mapEmbedFacility) {
                var multiMarkers = [];
                var coords = mapEmbedFacility.dataset.coordinates;
                var iconUrl = mapEmbedFacility.dataset.iconUrl;
                var zoom = mapEmbedFacility.dataset.zoom;
                var popupTitle = mapEmbedFacility.dataset.popupTitle;
                var popupDescription = mapEmbedFacility.dataset.popupDescription;
                var popupDirections = "";
                var popupContent = "";
                var map = L.map(mapEmbedFacility);
                var popupOnLoad = mapEmbedFacility.dataset.popup;
                // This fixes map already initialized error
                // var container = L.DomUtil.get(mapEmbedFacility);
                // if(container != null){
                //     container._leaflet_id = null;
                // }
                

                if(mapEmbedFacility.closest('.qhealth__service_contact-details__map__item')) {
                    if(mapEmbedFacility.closest('.qhealth__service_contact-details__map__item').querySelectorAll('.js-map-embed-facility__marker-details').length > 0) {
                        multiMarkers = QH.utils.listToArray(mapEmbedFacility.closest('.qhealth__service_contact-details__map__item').querySelectorAll('.js-map-embed-facility__marker-details'));
                    }
                }

                if(multiMarkers.length > 0) {


                    multiMarkers.forEach(function(singleMarker, index) {
                        popupDirections = `<div class="leaflet-popup-content-directions">
                                                <a href="${singleMarker.dataset.popupDirections}" target="_blank"><i class="fal fa-directions"></i>Get directions</a>
                                            </div>`;

                        popupContent = `<div class='leaflet-popup-content-details'>
                                                <h4>${singleMarker.dataset.popupTitle}</h4>
                                                <p>${singleMarker.dataset.popupDescription}</p>
                                            </div>
                                            ${popupDirections}`;

                        let icon = L.icon({
                            iconUrl: singleMarker.dataset.iconUrl ? singleMarker.dataset.iconUrl : iconUrl,
                            iconSize: [50, 50],
                            iconAnchor: [25, 50],
                        });

                        let popup = L.popup({
                            className:'qhealth_facility_tooltip',
                            closeButton:false,
                            closeOnClick:false,
                            autoClose:true,
                            offset: [180, 100]
                        }).setContent(popupContent);


                        coords = singleMarker.dataset.coordinates;
                        let coordArray = coords.split(';');

                        if (coordArray.length !== 2) {
                            return;
                        }

                        if(index === 0) {
                            map.setView(coordArray, parseInt(zoom));

                            let marker = new L.marker(coordArray, { icon: icon, alt: 'Map marker pin' }).addTo(map).bindPopup(popup);

                            if(popupOnLoad === "true") {
                                marker.openPopup();
                            }
                        } else {
                            let marker = new L.marker(coordArray, { icon: icon, alt: 'Map marker pin' }).addTo(map).bindPopup(popup);
                        }

                        // $(window).trigger('resize');  
                    });

                } else {
                    if(mapEmbedFacility.dataset.popupDirections) {
                        popupDirections = `<div class="leaflet-popup-content-directions">
                                                <a href="${mapEmbedFacility.dataset.popupDirections}" target="_blank"><i class="fal fa-directions"></i>Get directions</a>
                                            </div>`;
                    }

                    popupContent = `<div class='leaflet-popup-content-details'>
                                            <h4>${popupTitle}</h4>
                                            <p>${popupDescription}</p>
                                        </div>
                                        ${popupDirections}`;

                    let icon = L.icon({
                        iconUrl: iconUrl,
                        iconSize: [50, 50],
                        iconAnchor: [25, 50],
                    });

                    let coordArray = coords.split(';');

                    if (coordArray.length !== 2) {
                        return;
                    }
        
                    map.setView(coordArray, parseInt(zoom));

                    let popup = L.popup({
                        className:'qhealth_facility_tooltip',
                        closeButton:false,
                        closeOnClick:true,
                        keepInView:true,
                        offset: [180, 100]
                    })
                    .setLatLng(coordArray)
                    .setContent(popupContent)
                    .openOn(map);

                    let marker = new L.marker(coordArray, { icon: icon, alt: 'Map marker pin' }).addTo(map).bindPopup(popup);
                }

                L.esri.Vector.vectorBasemapLayer('ArcGIS:Topographic', {
                    apiKey:
                        'AAPK14c2db245cb5432da99353b71b6433dflik7DtfBuaNdb8nB-MjrF9DiaBOQkhlWuu0asMWlyHxAitjLsCJRPXPBU5blMVGr',
                }).addTo(map);

                setInterval(function() {
                    map.invalidateSize();
                 }, 400);
            });
        }, 100);
    }


    QH.facilityMap = facilityMap;

    document.addEventListener('DOMContentLoaded', QH.facilityMap.init);
})();
