/**
 * @module adminDetails
 */


(function () {

    var adminDetails = {};
    

    /**
     * Init the adminDetails page
     * 
     * @memberof module:adminDetails
     */
    adminDetails.init = function() {

    };


    // Make admin details public
    QH.adminDetails = adminDetails;

    // Init Admin Details
    window.addEventListener('DOMContentLoaded', function () {
    
    });
    
}());