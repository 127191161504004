// CSS
import "./styles/global.scss";

// JS
import $ from 'jquery';


window.jQuery = window.$ = $;
require('jquery-validation');
require("slick-carousel");
require("jszip");
require("datatables.net");
require("datatables.net-buttons");
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-plugins/sorting/file-size.js");
require("moment/min/moment.min.js");
require("datatables.net-plugins/sorting/datetime-moment.js");