(function () {
    'use strict';

    /**
     * @module newsArticleReadTime
     */
    var initNewsIndex = {};

    /**
     * Initialise News Index
     * 
     * @memberof module:newsFeed
     */
     initNewsIndex.init = function() {

        if(QH.utils.getParamaterByName('tag')) {
            if($('[data-action="tag-paramater"]').length > 0) {
                var $newsHeader = $('[data-action="tag-paramater"]');
                var tag = QH.utils.getParamaterByName('tag').replace(/_/g, ' ');

                $newsHeader.text(tag);
            }
        }
    };

    // Assign initNewsIndex to global QH object of functions
    QH.initNewsIndex = initNewsIndex;

    
    document.addEventListener('DOMContentLoaded', initNewsIndex.init);
}());