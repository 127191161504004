/**
 * @module newsFeed
 */
(function () {
    "use strict";

    /**
     * Initialise the slick slider in the news feed
     * 
     * @memberof module:newsFeed
     * @instance
     * @private
     */
    function initNewsFeed() {

        //check if more than two items
        if($('.qhealth__news-feed__item').length > 2) {

            $('.qhealth__news-feed').slick({
                infinite: true,
                slidesToShow: 2.99,
                centerMode: false,
                slidesToScroll: 1,
                adaptiveHeight:true,
                dots: true,
                respondTo:'min',
                appendArrows:$('.qhealth__news-feed-controls--content'),
                appendDots:$('.qhealth__news-feed-controls--content'),
                prevArrow:'<button type="button" class="controls-prev"><i class="fa fa-chevron-left"></i></button>',
                nextArrow:'<button type="button" class="controls-next"><i class="fa fa-chevron-right"></i></button>',
                responsive: [
                    {
                        breakpoint:1024,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint:591,
                        settings: {
                            slidesToShow: 1

                        }
                    }
                ]
            });
        }
    }
    
    document.addEventListener('DOMContentLoaded', initNewsFeed);
})();
