/**
 * @module topServicesForm
 */

 /* Select 2 */
 import 'select2';
 import 'select2/dist/css/select2.css';
 
 /* jQuery Sortable */
 import 'jquery-ui/ui/widgets/sortable';
 
 (function () {
 
    var topServicesForm = {};
    topServicesForm.maxCount = 6;
    topServicesForm.newList = [];
    topServicesForm.oldList = [];

    /**
     * Init the Service Finder admin form. This includes adding
     * event listeners, as well as initializing the tinyMCE Plugin
     * on Appropriate TextAreas 
     * 
     * @memberof module:topServicesForm
     */
    topServicesForm.init = function() {
        
        // Add extra validation
        topServicesForm.addValidationRules();
        
        // Init jQuery validate
        QH.forms.init();

        // Get auth token for JWT
        serviceFinderData.jwt.getAuthToken();

        // Form
        var $form = $('.sf-admin__top-services-form form');

        // Set top services var
        topServicesForm.getServicesFromList();
        topServicesForm.oldList = [...topServicesForm.newList];

        // jQuery Sortable init
        $('#sf-field__top-services').sortable({
            update: function() {
                $('.sf-admin__top-services-form input[type="submit"]').attr('disabled', false);
                topServicesForm.getServicesFromList();
            }
        });

        // Init Select2 for fields with more than 2 options
        $form.find('select').each(function() {
            var $select = $(this);
            if ($select.children().length > 2) {
                $select.select2();
            }
        });

        // Handle add top services
        $('#sf-admin__add-service').on('click', topServicesForm.addService);

        // Handle delete of top service
        $('#sf-field__top-services .qhealth__admin--delete').on('click', topServicesForm.deleteService);

        // Handle Submit
        $form.on('submit', topServicesForm.handleSubmit);
    };

    /**
     * Handle addition of new top service to the list
     * 
     * @memberof module:topServicesForm
     */
    topServicesForm.addService = function(e) {
        e.preventDefault();

        // Get service id and name
        var $selectField = $('#sf-field__service');
        var newService = $selectField.val();
        var friendlyName = $('#sf-field__service :selected').data('friendly');

        // Set select2 field to empty and disable add button
        $selectField.val(null).trigger('change');
        $('#sf-admin__add-service').attr('disabled', 'disabled');

        topServicesForm.addItemToList(newService, friendlyName);
    };

    /**
     * Handle addition of new top service to the list
     * 
     * @memberof module:topServicesForm
     */
     topServicesForm.addItemToList = function(id, friendlyName) {

        // Remove item from deteled list if it exists
        $('#sf-field__top-services--deleted li[data-id="' + id + '"][data-friendly="' + friendlyName + '"]').remove();

        // Add list item to sortable list
        $('#sf-field__top-services').append('<li data-id="' + id + '" data-friendly="' + friendlyName + '"><span class="qhealth__btn qhealth__btn--dark"><i class="fa fa-arrows-v"></i> ' + friendlyName + '</span><button class="qhealth__btn qhealth__btn--tertiary qhealth__admin--delete " title="Delete"><i class="fas fa-trash"></i></button></li>');
        $('#sf-field__top-services').sortable('refresh');

        // Delete/re-add delete button listeners
        $('#sf-field__top-services .qhealth__admin--delete').off();
        $('#sf-field__top-services .qhealth__admin--delete').on('click', topServicesForm.deleteService);
        
        // Repopulate 'newList' from sortable list items
        topServicesForm.getServicesFromList();

        // Enable submit button
        $('.sf-admin__top-services-form input[type="submit"]').attr('disabled', false);

        // If we have max number of services, show message and disable select2 field
        if (topServicesForm.newList.length >= topServicesForm.maxCount) {
            $('#sf-admin__top-services-form__max').show();
            $('#sf-field__service').attr('disabled', true);
            $('.qhealth__admin--undo').attr('disabled', true);
        }
    };

    /**
     * Handle deletion of new top service to the list
     * 
     * @memberof module:topServicesForm
     */
     topServicesForm.deleteService = function(e) {
        e.preventDefault();

        $('.qhealth__admin--undo').attr('disabled', false);

        // Add item to 'deleted' list
        var $serviceItem = $(this).closest('li');
        var deletedId = $serviceItem.attr('data-id');
        var friendlyName = $serviceItem.attr('data-friendly');
        $('#sf-field__top-services--deleted').append('<li data-id="' + deletedId + '" data-friendly="' + friendlyName + '"><span class="qhealth__btn qhealth__btn--dark" disabled="disabled">' + friendlyName + '</span><button class="qhealth__btn qhealth__admin--undo " title="Undo"><i class="fas fa-undo"></i></button></li>');
        $('#sf-field__top-services--deleted li[data-id="' + deletedId + '"][data-friendly="' + friendlyName + '"] .qhealth__admin--undo').on('click', topServicesForm.undo);

        // Delete parent list item and update sortable list
        $serviceItem.remove();
        $('#sf-field__top-services').sortable('refresh');

        // Repopulate 'newList' from sortable list items
        topServicesForm.getServicesFromList();

        // Enable submit button
        $('.sf-admin__top-services-form input[type="submit"]').attr('disabled', false);

        // Hide 'max' error message an enable select2 field
        $('#sf-admin__top-services-form__max').hide();
        $('#sf-field__service').attr('disabled', false);
    };

    topServicesForm.undo = function(e) {
        var $serviceItem = $(this).closest('li');
        var deletedId = $serviceItem.attr('data-id');
        var friendlyName = $serviceItem.attr('data-friendly');

        topServicesForm.addItemToList(deletedId, friendlyName);
    }

    /**
     * Populate topServicesForm.newList with the items in the sortable list
     * 
     * @memberof module:topServicesForm
     */
    topServicesForm.getServicesFromList = function() {
        topServicesForm.newList = [];
        $('#sf-field__top-services li').each(function() {
            var serviceId = $(this).data('id');
            topServicesForm.newList.push(serviceId);
        });
    }

    /**
     * Add custom jQuery validate rules to the form
     * 
     * @memberof module:topServicesForm
     */
    topServicesForm.addValidationRules = function() {

        // Validation rule for top service select
        $.validator.addMethod("validtopservice", function(value, element) {
            var isValid = topServicesForm.newList.indexOf(value) === -1;
            
            // Enable the 'add' button if service is valid
            if (isValid) {
                $('#sf-admin__add-service').attr('disabled', null);
            
            // Disable the 'add' button if service is NOT valid
            } else {
                $('#sf-admin__add-service').attr('disabled', 'disabled');
            }
            return this.optional(element) || (isValid);
        }, "This is already a top service");
    };


    /**
     * Handle submit of the top services form
     * 
     * @memberof module:topServicesForm
     * 
     * @param {Document.event} e 
     */
    topServicesForm.handleSubmit = function(e) {
        e.preventDefault();
        topServicesForm.toggleError(false);

        // Get form variables
        var $form = $(this);
        var parent = $form.closest('.sf-admin__top-services-form');
        var collection = $form.data('collection').replace(/_/g, '-');
        var documentId = $form.data('document');
        var updatedTopServices = topServicesForm.newList;
        parent.addClass('loading');

        // Get data from JWT
        var updatedBy = serviceFinderData.jwt.getPayload().fullName;
        var updatedDate = Date.now();

        // Get the document and update the 'top_services' array
        serviceFinderData.collection(collection).doc(documentId).get().then((document) => {
            document.top_services = updatedTopServices;
            document.updatedBy = updatedBy;
            document.updatedDate = updatedDate;
            console.log('updated document', document);

            serviceFinderData.collection(collection).doc(documentId).update(document).then(() => {
                
                // Get array of updated (ie. added/deleted) services
                var uniqueOld = topServicesForm.oldList.filter((service) => topServicesForm.newList.indexOf(service) === -1);
                var uniqueNew = topServicesForm.newList.filter((service) => topServicesForm.oldList.indexOf(service) === -1);
                var updatedServices = uniqueOld.concat(uniqueNew);

                // For each updatedService, update the 'top_in_hhs' or 'top_in_facilities' array
                if (updatedServices.length > 0)  {
                    updatedServices.forEach((serviceId, index) => {

                        serviceFinderData.collection('services').doc(serviceId).get().then((service) => {
                            
                            // Add the document
                            if (topServicesForm.newList.indexOf(serviceId) !== -1) {
                                if (service['top_in_' + collection].indexOf(documentId) === -1) {
                                    service['top_in_' + collection].push(documentId);
                                }

                            // Remove the document
                            } else {
                                var deleteIndex = service['top_in_' + collection].indexOf(documentId);
                                if (deleteIndex > -1) {
                                    service['top_in_' + collection].splice(deleteIndex, 1);
                                }
                            }
                            
                            service.updatedBy = updatedBy;
                            service.updatedDate = updatedDate;
                            
                            // Update service in Datastore
                            serviceFinderData.collection('services').doc(serviceId).update(service).then((service) => {
                                console.log('service updated:', service);

                                // After last service updated, redirect to document
                                if (index >= updatedServices.length - 1) {
                                    topServicesForm.redirectToDocument($form, documentId);
                                }
                            
                            // Error: Fail to UPDATE service
                            }).catch((error) => {
                                console.log('Failed to update service');
                                console.error(error);
                                topServicesForm.toggleError(true);
                                parent.removeClass('loading');
                            });

                        // Error: Fail to GET service
                        }).catch((error) => {
                            console.log('Failed to get service');
                            console.error(error);
                            topServicesForm.toggleError(true);
                            parent.removeClass('loading');
                        });
                    });

                // If we have only re-ordered the top services
                } else {
                    topServicesForm.redirectToDocument($form, documentId);
                }
            
            // Error: Fail to UPDATE document
            }).catch((error) => {
                console.log('Failed to update document');
                console.error(error);
                topServicesForm.toggleError(true);
                parent.removeClass('loading');
            });

        // Error: Fail to GET document
        }).catch((error) => {
            console.log('Failed to get document');
            console.error(error);
            topServicesForm.toggleError(true);
            parent.removeClass('loading');
        });
    };

    /**
     * Toggle the main top services form error
     * 
     * @memberof module:topServicesForm
     * 
     * @param {boolean} showError 
     */
    topServicesForm.toggleError = function(showError) {
        if (showError) {
            $('#sf-admin-form__error').addClass('active');
        } else {
            $('#sf-admin-form__error').removeClass('active');
        }
    }

    /**
     * Redirect to the edtied document
     * 
     * @memberof module:topServicesForm
     * 
     * @param {jQuery} $form 
     */
    topServicesForm.redirectToDocument = function($form, docId) {
        var collection = $form.data('collection').replace(/_/g, '-');
        var detailsURL = $form.attr('action') + '?collection=' + collection + '&document=' + docId;
        console.log('redirecting to ' + detailsURL);
        window.location = detailsURL;
    }

    // Make admin form public
    QH.topServicesForm = topServicesForm;

    // Init Admin Form
    $(document).ready(function() {
        if ($('.sf-admin__top-services-form form').length > 0) {
            QH.topServicesForm.init();
        }
    });
     
 }());