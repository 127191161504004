/**
 * @module map
 */
(function () {

    /**
     * Initialise all embedded maps on a particular page
     * 
     * @memberof module:map
     * @instance
     * @private
     * 
     */
    function initMaps() {
        var mapEmbeds = QH.utils.listToArray(document.querySelectorAll('.js-map-embed'));

        // For all maps
        mapEmbeds.forEach(function (mapEmbed) {
            // var coords = "-27.716239354542;151.632346684337"
            var coords = mapEmbed.dataset.coordinates;
            var iconUrl = mapEmbed.dataset.iconUrl;
            var coordArray = coords.split(';');

            if (coordArray.length !== 2) {
                return;
            }

            var map = L.map(mapEmbed).setView(coordArray, 15);

            L.esri.Vector.vectorBasemapLayer('ArcGIS:Streets', {
                apiKey:
                    'AAPK14c2db245cb5432da99353b71b6433dflik7DtfBuaNdb8nB-MjrF9DiaBOQkhlWuu0asMWlyHxAitjLsCJRPXPBU5blMVGr',
            }).addTo(map);

            var icon = L.icon({
                iconUrl: iconUrl,
                iconSize: [50, 50],
                iconAnchor: [25, 50],
                popupAnchor: [0, -11],
            });

            L.marker(coordArray, { icon: icon, alt: 'map location marker' }).addTo(map);
        });
    }

    document.addEventListener('DOMContentLoaded', initMaps);
})();
