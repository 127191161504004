(function () {
    'use strict';

    /**
     * @module servicesSearchFacets
    */
     var servicesSearchFacets = {};

     /**
     * Initialise Services Search Form functionality
     * 
     * @memberof module:servicesSearchFacets
     */
    servicesSearchFacets.init = function () {
        var $expandAllFacets = $('.qhealth__service_search__facets__expand-all');
        var allExpanded = false;
        var $facetBtns = $('.qhealth__service_search__facets .js-qhealth__accordion');

        $expandAllFacets.on('click', () => {
            let $accordions = $expandAllFacets.closest('.qhealth__accordion__body').find('.js-qhealth__accordion');

            if(!allExpanded) {
                $accordions.each(function() {
                    let $this = $(this);

                    $this.removeClass('qhealth__accordion--closed').addClass('qhealth__accordion--open');
                    $this.next().removeClass('qhealth__accordion--closed').addClass('qhealth__accordion--open');
                });

                $expandAllFacets.text('Collapse all');
                allExpanded = true;
            } else {
                $accordions.each(function() {
                    let $this = $(this);

                    $this.removeClass('qhealth__accordion--open').addClass('qhealth__accordion--closed');
                    $this.next().removeClass('qhealth__accordion--open').addClass('qhealth__accordion--closed');
                }); 

                $expandAllFacets.text('Expand all');
                allExpanded = false;
            }
        });

        $facetBtns.on('click', () => {
            setTimeout(function() {
                let expandedCounter = 0;

                $facetBtns.each(function() {
                    if($(this).hasClass('qhealth__accordion--open')) {
                        expandedCounter++;
                    }
                });
    
                if(expandedCounter > 0) {
                    $expandAllFacets.text('Collapse all');
                    allExpanded = true;
                } else if(expandedCounter === 0) {
                    $expandAllFacets.text('Expand all');
                    allExpanded = false;
                }
            }, 50)
        });

        let serviceCategoriesUrl = "https://au-a14fc409.datastore.squiz.cloud/service-categories";

        $.get(serviceCategoriesUrl, function(data) {
            let serviceCats = data;
            
            $('.qhealth__service_search__facets__section__item li a').each(function() {
                let $this = $(this);

                for(let i = 0; i < serviceCats.length; i++) {
                    if(serviceCats[i].id === $this.text()) {
                        $this.text(serviceCats[i].name);
                    }
                }
            });
        });
    };

    QH.servicesSearchFacets = servicesSearchFacets;

    document.addEventListener('DOMContentLoaded', QH.servicesSearchFacets.init);
}());