
(function(QH) {
    "use strict";
    
    /**
     * @module accessibilityFilters
     */
    var accessibilityFilters = {};

    /**
     * Initialise the accessibility filters for example components
     * 
     * @memberof module:accessibilityFilters
     */
    accessibilityFilters.init = function() {
        $(document).on('change', 'input[type=radio][name=qhealth__accessibility-filter]', function() {
            var $this = $(this);

            $this.closest('.qhealth__example__inline').removeClass(function(i, className) {
                return (className.match (/(^|\s)qhealth__accessibility-filter__\S+/g) || []).join(' ');
            }).addClass($this.val());
        });
    }

    var QH = QH ? QH : {};
    QH.accessibilityFilters = accessibilityFilters;
    window.QH = QH;

    $(document).ready(function() {
        if ($(".qhealth__example__accessibility-filters").length > 0) {
            QH.accessibilityFilters.init();
        }
    });

}(window.QH));