(function () {
    'use strict';

    /**
     * @module video
     */
     var video = {};

    /**
     * Initialise all videos on a particular page
     * 
     * @memberof module:video
     */
    video.init = function() {
        var videos = document.querySelectorAll('.qhealth__video');

        videos.forEach((video) => {
            var videoContainer = $(video).find('.qhealth__video_vid');
            var videoType = videoContainer.attr('data-type');

            if (videoType === 'youtube') {
                QH.video.initYoutube(video);
            } else if (videoType === 'vimeo') {
                QH.video.initVimeo(video);
            }
        });
    };
    
    /**
     * Initialise a given video from YouTube
     * 
     * @memberof module:video
     * 
     * @param {Node} video  The main Video container DOM element
     */
    video.initYoutube = function(video) {
        var videoContainer = $(video).find('.qhealth__video_vid');
        var videoId = videoContainer.attr('data-video-id');
        var playerId = videoContainer.find('.qhealth__video_player').attr('id');
        var playButton = video.querySelector('.qhealth__video__thumbnail-playbtn');

        var player = new YT.Player(playerId, {
            height: "100%",
            width: "100%",
            videoId: videoId,
            playerVars: {
                "playsinline": 1,
                "origin": window.location.origin
            }
        });

        var origin = window.location.origin
        player.sendMessage = function (a) {
            a.id = this.id, a.channel = "widget", a = JSON.stringify(a);
            var url = new URL(this.i.src);
            if (origin && this.i.contentWindow) {
                this.i.contentWindow.postMessage(a, origin)
            }
        }

        playButton.addEventListener('click',(e)=>{
            e.preventDefault();
            
            //hide the thumbnail
            $(playButton).parent().fadeOut(200);           
            
            //show video
            setTimeout( function(){
                videoContainer.removeClass('hidden');
                player.playVideo();               
            }, 195);
        });
    };

    /**
     * Initialise a given video from Vimeo
     * 
     * @memberof module:video
     * 
     * @param {Node} video  The main Video container DOM element
     */
    video.initVimeo = function(video) {
        var videoContainer = $(video).find('.qhealth__video_vid');
        var playButton = video.querySelector('.qhealth__video__thumbnail-playbtn');

        playButton.addEventListener('click',(e)=>{
            e.preventDefault();                
            
            //show video and set url of iframe
            setTimeout( function(){
                videoContainer.removeClass('hidden');
                var video_url = videoContainer.data('source') + '?autoplay=1';
                videoContainer.find('iframe').prop('src', video_url) ;                   
            }, 195);
            
            //hide the thumbnail
            $(playButton).parent().fadeOut(200);
        });
    };

    /**
     * Load the YouTube IFrame Player API code asynchronously.
     * 
     * @memberof module:video
     */
    video.loadIframeApi = function() {
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    $(document).ready(function() {
        QH.video = video;
    
        // Load YouTube IFrame API and then initialise videos when ready
        if (document.querySelectorAll('.qhealth__video').length){
            QH.video.loadIframeApi();
        }
    });
    
    if (document.querySelectorAll('.qhealth__video').length){
        window.onYouTubeIframeAPIReady = function() {
            QH.video.init();
            console.log('ok');
        }
    }
    
}());