(function () {

    'use strict';


    /**
     * @module servicesModal
     */
    var topServicesModal = {};
    
    /**
     * Initialise modal funtionality
     * 
     * @memberof module:servicesModal
     */
    topServicesModal.init = function() {
        var modals = document.querySelectorAll('.qhealth__modal');
        var modalUnderlay = document.querySelectorAll('.qhealth__modal__underlay');
        var modalBtn = document.querySelectorAll('.qhealth__open-modal');
        var getLocationBtn = document.querySelectorAll('.qhealth__geo-location__request');
        var locationDenyBtn = document.querySelectorAll('.qhealth__geo-location__deny ');

        if(modalBtn) {
            // loop over each button found that opens a modal and setup modals
            for (let i = 0; i < modalBtn.length; i++) {
                modalBtn[i].addEventListener('click', function() {
                    let modalOpenBtn = this;
                    let modalTarget = this.getAttribute('data-modaltarget');
                    let modalEl = document.querySelector(`#${modalTarget}`);

                    if(modalEl) {
                        let modalCloseBtn = modalEl ? modalEl.querySelector('.qhealth__modal__close') : null;
                        let modalDenyBtn = modalEl ? modalEl.querySelector('.qhealth__modal__deny') : null;
                        let modalAcceptBtn = modalEl ? modalEl.querySelector('.qhealth__modal__accept') : null;
                        // trap focus
                        // add all the elements inside modal that are focusable
                        let focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
                        let firstFocusableElement = modalEl.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
                        let focusableContent = modalEl.querySelectorAll(focusableElements);
                        let lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

                        document.addEventListener('keydown', function(e) {
                            let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

                            if (!isTabPressed) {
                                return;
                            }

                            if (e.shiftKey) { // if shift key pressed for shift + tab combination
                                if (document.activeElement === firstFocusableElement) {
                                    lastFocusableElement.focus(); // add focus for the last focusable element
                                    e.preventDefault();
                                }
                            } else { // if tab key is pressed
                                if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
                                    firstFocusableElement.focus(); // add focus for the first focusable element
                                    e.preventDefault();
                                }
                            }
                        });

                        modalEl.classList.add('active');
                        modalEl.focus();     
                        
                        // if modal is update-location then initialise userLocationFinder
                        if(modalEl.classList.contains('qhealth__update-location')) {
                            QH.userLocationFinder.init();
                        }

                        if(modalCloseBtn) {
                            modalCloseBtn.addEventListener('click', function(e) {
                                modalEl.classList.remove('active');
                                modalOpenBtn.focus();
                            });
                        }

                        if(modalDenyBtn) {
                            modalDenyBtn.addEventListener('click', function(e) {
                                modalEl.classList.remove('active');
                                modalOpenBtn.focus();
                            });
                        }

                        if(modalAcceptBtn) {
                            modalAcceptBtn.addEventListener('click', function(e) {
                                modalEl.classList.remove('active');
                                modalOpenBtn.focus();
                            });
                        }
                    } else {
                        console.log(`Modal '#${modalTarget}' not found.`)
                    }
                });

                
            }
        }

        if(modalUnderlay) {
            for (let i = 0; i < modalUnderlay.length; i++) {
                modalUnderlay[i].addEventListener('click', function() {
                    this.parentNode.classList.remove('active');
                });
            }
        }
    
        if(getLocationBtn) {
            for (let i = 0; i < getLocationBtn.length; i++) {

                getLocationBtn[i].addEventListener('click', function() {
                    QH.utils.geolocateUser();
                });
            }
        }
    
        if(locationDenyBtn) {
            for (let i = 0; i < locationDenyBtn.length; i++) {
                locationDenyBtn[i].addEventListener('click', function() {
                    QH.utils.setLocalStorage('qh_geolocation', 'deny')
                });
            }
        }

        if(modals && QH.utils.getLocalStorage('qh_geolocation') !== 'deny') {
            for (let i = 0; i < modals.length; i++) {
                if(modals[i].classList.contains('qhealth__services-modal')) {
                    var modalId = modals[i].id;
                    var modalToActivate = document.querySelector(`[data-modaltarget=${modalId}]`);

                    console.log(modalId);

                    if(!QH.utils.getLocalStorage('qh_user_location')) {
                        if(modalToActivate) {
                            modalToActivate.click();
                        }
                    }
                }
            }
        }
    
    }
    
    QH.topServicesModal = topServicesModal;

    window.addEventListener('DOMContentLoaded', function () {
        // QH.topServicesModal.init();
    });
    
}());