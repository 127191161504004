/**
 * Address Autocomplete - Google
 * 
 * @module addressAutocompleteGoogle
 */

(function() {
    "use strict";

    // Service finder search module
    var serviceFinder = {
        autocomplete: null,
        searchField: null
    };

    // Add to global scope
    window.serviceFinder = serviceFinder;

    /**
     * function for initialisting autocomplete
     * 
     * @memberof module:addressAutocompleteGoogle
     * @instance
     * @private
     */
    serviceFinder.initAutocomplete = function() {
        serviceFinder.searchField = document.querySelector("#query-inner");
        // Create the autocomplete object, restricting the search predictions to
        // addresses in Australia
        serviceFinder.autocomplete = new google.maps.places.Autocomplete(serviceFinder.searchField, {
            componentRestrictions: {
                country: ["au"]
            },
            fields: ["address_components", "geometry"],
            types: ["(regions)"]
        });
        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        serviceFinder.autocomplete.addListener("place_changed", serviceFinder.fillInAddress);
    }

    /**
     * function for hiding origin field with coords and enable submit
     * 
     * @memberof module:addressAutocompleteGoogle
     * @instance
     * @private
     */

    serviceFinder.fillInAddress = function() {
        // Get the place details from the autocomplete object.
        const place = serviceFinder.autocomplete.getPlace();
        document.querySelector("#query-origin").value = place.geometry.location.lat() + ',' + place.geometry.location.lng();
        document.querySelector("#query-submit").disabled = false;
    }

    
    /**
     * function for loading Google Places API
     * 
     * @memberof module:addressAutocompleteGoogle
     * @instance
     * @private
     * 
     * @param  {string} key - api key
     */

    serviceFinder.loadScript = function(key) {
        var script_tag = document.createElement('script');
        script_tag.setAttribute("type","text/javascript");
        script_tag.setAttribute("src","https://maps.googleapis.com/maps/api/js?key=" + key + "&callback=serviceFinder.initAutocomplete&libraries=places&v=weekly");
        document.getElementById('footer_js').appendChild(script_tag);
    }


    /**
     * function for initial page load, check for service finder form and add script if it exists
     * 
     * @memberof module:addressAutocompleteGoogle
     * @instance
     * @private
     * 
     * @param  {object} event - window event object
     */
    window.addEventListener('DOMContentLoaded', function(event) {

        var form = document.getElementById('qhealth__service-finder__google');
        var key = null;
        if(form!== null) {
            var key = form.getAttribute('data-api') || 'AIzaSyBXI46wibJ5QLPfSNhVZpVAziarwGCPPf8';
        }
        if (form !== null && key !== null) {
            serviceFinder.loadScript(key);
        }
    });

})();


/**
 * Address Autocomplete - Australia Post
 * 
 * @module addressAutocompleteAusPost
 */

 (function() {

    //import BloodHound.js (typeahead) 
    var $ = require('jquery');
    var Bloodhound = require('corejs-typeahead/dist/bloodhound.js');
    require('corejs-typeahead/dist/typeahead.jquery.js');
    

    "use strict";

    // Service finder search module
    var serviceFinder = {
        autocomplete: null,
        searchField: null
    };

    // Add to global scope
    window.serviceFinder = serviceFinder;

    /**
     * function for initialisting autocomplete
     * 
     * @memberof addressAutocompleteAusPost
     * @instance
     * @private
     */

    serviceFinder.initAutocomplete = function(key) {
        // Create the autocomplete object, restricting the search predictions to
        // addresses in QLD

        var addressPacket = new Bloodhound({
            datumTokenizer: function(d) {
                var locationTokens = Bloodhound.tokenizers.whitespace(d.location);
                var postcodeTokens = Bloodhound.tokenizers.whitespace(d.postcode);       
                return locationTokens.concat(postcodeTokens);
            },
            identify: function(d) { return d.id; },
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            limit: 20,
            remote: {
              url: 'https://qhscb.squiz.cloud/core/_designs/connectors/rest/get-auspost-data',
              prepare: function (query, settings) {
                settings.url = encodeURI(settings.url + '?query=' + query);
                return settings;
              }
            }
            // local: [
            //             {
            //                 "category": "Post Office Boxes",
            //                 "id": 8535,
            //                 "location": "BRISBANE",
            //                 "postcode": 4001,
            //                 "state": "QLD"
            //             },
            //             {
            //                 "category": "Delivery Area",
            //                 "id": 8530,
            //                 "latitude": -27.466785,
            //                 "location": "BRISBANE ADELAIDE STREET",
            //                 "longitude": 153.027153,
            //                 "postcode": 4000,
            //                 "state": "QLD"
            //             },
            //             {
            //                 "category": "Delivery Area",
            //                 "id": 8549,
            //                 "latitude": -27.38837225,
            //                 "location": "BRISBANE AIRPORT",
            //                 "longitude": 153.1132012,
            //                 "postcode": 4008,
            //                 "state": "QLD"
            //             },
            //             {
            //                 "category": "Delivery Area",
            //                 "id": 8531,
            //                 "latitude": -27.46977074,
            //                 "location": "BRISBANE CITY",
            //                 "longitude": 153.0251227,
            //                 "postcode": 4000,
            //                 "state": "QLD"
            //             },
            //             {
            //                 "category": "Delivery Area",
            //                 "id": 8532,
            //                 "latitude": -27.467288,
            //                 "location": "BRISBANE GPO",
            //                 "longitude": 153.028629,
            //                 "postcode": 4000,
            //                 "state": "QLD"
            //             },
            //             {
            //                 "category": "Delivery Area",
            //                 "id": 8709,
            //                 "latitude": -27.533576,
            //                 "location": "BRISBANE MARKET",
            //                 "longitude": 152.9993058,
            //                 "postcode": 4106,
            //                 "state": "QLD"
            //             },
            //             {
            //                 "category": "Delivery Area",
            //                 "id": 8822,
            //                 "latitude": -27.48334153,
            //                 "location": "EAST BRISBANE",
            //                 "longitude": 153.0467209,
            //                 "postcode": 4169,
            //                 "state": "QLD"
            //             },
            //             {
            //                 "category": "Delivery Area",
            //                 "id": 8836,
            //                 "latitude": -27.39000408,
            //                 "location": "PORT OF BRISBANE",
            //                 "longitude": 153.1782025,
            //                 "postcode": 4178,
            //                 "state": "QLD"
            //             },
            //             {
            //                 "category": "Post Office Boxes",
            //                 "id": 8596,
            //                 "location": "ROYAL BRISBANE HOSPITAL",
            //                 "postcode": 4029,
            //                 "state": "QLD"
            //             },
            //             {
            //                 "category": "Delivery Area",
            //                 "id": 8697,
            //                 "latitude": -27.47806509,
            //                 "location": "SOUTH BRISBANE",
            //                 "longitude": 153.0193998,
            //                 "postcode": 4101,
            //                 "state": "QLD"
            //             },
            //             {
            //                 "category": "Delivery Area",
            //                 "id": 8698,
            //                 "latitude": -19.7061005,
            //                 "location": "SOUTH BRISBANE BC",
            //                 "longitude": 145.7740021,
            //                 "postcode": 4101,
            //                 "state": "QLD"
            //             }
            //         ]
        });

        

        $("#query-inner").typeahead(null, {
            name: 'addresses',
            minLength: 3,
            display: 'location',
            limit: 10,
            source: addressPacket.ttAdapter(),
            templates: {
                suggestion: function(data) {
                    return `<p><strong>${data.location}</strong>, ${data.postcode}</p>`;
                }
            }
        }).on('typeahead:select', function (obj, datum, name) {
            serviceFinder.fillInAddress(datum);
        });

        


        // When the user selects an address from the drop-down, populate the
        // address fields in the form.
        //serviceFinder.autocomplete.addListener("place_changed", serviceFinder.fillInAddress);
    }

    /**
     * function for hiding origin field with coords and enable submit
     * 
     * @memberof addressAutocompleteAusPost
     * @instance
     * @private
     */

    serviceFinder.fillInAddress = function(data) {
        if(data.latitude && data.longitude) {
            document.querySelector("#query-origin").value = data.latitude + ',' + data.longitude;
            document.querySelector("#query-submit").disabled = false;
        }
    }



    /**
     * function for initial page load, check for service finder form and add script if it exists
     * 
     * @memberof addressAutocompleteAusPost
     * @instance
     * @private
     * 
     * @param  {object} event - window event object
     */
    window.addEventListener('DOMContentLoaded', function(event) {

        var form = document.getElementById('qhealth__service-finder__auspost');
        var key,minlength = null;
        if(form!== null) {
            key = form.getAttribute('data-api') || '7c9a75a2-72a7-48c2-9809-d05f58b01133';
            minlength= form.getAttribute('data-minlength') || 3;
        }
        if (form !== null && key !== null) {
            serviceFinder.initAutocomplete(key);
        }
    });

})();





