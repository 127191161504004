// import '.././../../externals/funnelback.autocompletion-2.6.0.js';


(function () {
    'use strict';

    /**
     * @module servicesSearchForm
    */
    var servicesSearchForm = {};

    /**
    * Initialise Services Search Form functionality
    * 
    * @memberof module:servicesSearchForm
    */
    servicesSearchForm.init = function() {
      // Remove this and add to global data.links in dsComponentPreview.js
      let serviceFinderUrl = typeof sfLinks !== 'undefined' ? sfLinks.servicesHome : ``;
      let serviceSearchUrl = typeof sfLinks !== 'undefined' ? sfLinks.servicesSearch : ``;
      let sfHHS = typeof site !== 'undefined' ? site.metadata.sfHHS.value : ``;
      // vars for structuring services/service locations for geolocation autocomplete
      var fbUrl = "https://qh-search.squiz.cloud/s/search.html";
      var fbServicesCollection = "qld-health-core-service-finder__services"
      var fbFacilitiesCollection = "qld-health-core-service-finder__facilities"
      var fbServiceLocationsCollection = "qld-health-core-service-finder__service-locations";
      var fbProfile = "auto-completion";
      var fbForm = "qc";
      var fbNumRanks = 4;
      var coords = $("#query-origin").val();
      var currQuery = "";
      var currUserLocation = JSON.parse(QH.utils.getLocalStorage('qh_user_location'));
      currUserLocation = currUserLocation !== null ? currUserLocation.location : '';

      // window.Bloodhound = require('corejs-typeahead/dist/bloodhound.js');
      

      $('.qhealth__service-finder input.query').typeahead({
        hint: false,
        highlight: true,
        minLength: 3,
        limit: 4
      },
      {
        name: 'services',
        displayKey: 'disp',
        source: function(query, sync, async) {
          $.getJSON(`${fbUrl}?collection=${fbServicesCollection}&profile=${fbProfile}&form=${fbForm}&num_ranks=${fbNumRanks}&query=${query}&meta_hhsid_orsand=${sfHHS}`, function( data ) {
            currQuery = query;
            async(data);
         })
        },
        templates: {
          header: function() {
            return `<h4 class="tt-category">Services</h4>`;
          },
          suggestion: function(data) {
            return [
              `<div class="qhealth__search__autocomplete__suggestion tt-suggestion tt-selectable">`,
                `<a href="${serviceFinderUrl}/${data.path}"><strong class="tt-highlight">${data.disp}</strong></a>`,
              `<div>`
            ].join('\n');
          },
          notFound: function() {
            return [
              `<h4 class="tt-category">Services</h4>`,
              `<div class="qhealth__search__autocomplete__no-suggestions">`,
                `<em>No suggestions found</em>`,
              `<div>`
            ].join('\n');
          },
          footer: function() {
            return [
              `<div class="more-results">`,
                `<a href="${serviceSearchUrl}?query=${currQuery}&collection=${fbServicesCollection}" class="qhealth__btn qhealth__btn--primary qhealth__search__autocomplete__see-more"><span class="qhealth__search__submit-btn-text">See more</span> <span class="sr-only">results for your query within services</span></a>`,
              `<div>`
            ].join('\n')
          }
        },
      },
      {
        name: 'facilities',
        displayKey: 'disp',
        source: function(query, sync, async) {
          $.getJSON(`${fbUrl}?collection=${fbFacilitiesCollection}&profile=${fbProfile}&form=${fbForm}&num_ranks=${fbNumRanks}&query=${query}&meta_hhsid_orsand=${sfHHS}`, function( data ) {
            async(data);
         })
        },
        templates: {
          header: function() {
            return `<h4 class="tt-category">Hospitals and Health Services</h4>`;
          },
          suggestion: function(data) {
            return [
              `<div class="qhealth__search__autocomplete__suggestion tt-suggestion tt-selectable">`,
                `<a href="${serviceFinderUrl}/locations/${data.path}"><strong class="tt-highlight">${data.disp}</strong></a>`,
              `<div>`
            ].join('\n');
          },
          notFound: function() {
            return [
              `<h4 class="tt-category">Hospitals and Health Services</h4>`,
              `<div class="qhealth__search__autocomplete__no-suggestions">`,
                `<em>No suggestions found</em>`,
              `<div>`
            ].join('\n');
          },
          footer: function() {
            return [
              `<div class="more-results">`,
                `<a href="${serviceSearchUrl}?query=${currQuery}&collection=${fbFacilitiesCollection}" class="qhealth__btn qhealth__btn--primary qhealth__search__autocomplete__see-more"><span class="qhealth__search__submit-btn-text">See more</span> <span class="sr-only">results for your query within services</span></a>`,
              `<div>`
            ].join('\n');
          }
        },
      },
      {
        name: 'service-locations',
        displayKey: 'disp',
        source: function(query, sync, async) {
          $.getJSON(`${fbUrl}?collection=${fbServiceLocationsCollection}&profile=${fbProfile}&form=${fbForm}&num_ranks=${fbNumRanks}&query=${query}&origin=${coords}&sort=prox&meta_hhsid_orsand=${sfHHS}`, function( data ) {
            async(data);
         })
        },
        templates: {
          header: function() {
            return `<h4 class="tt-category">Near ${currUserLocation}</h4>`;
          },
          suggestion: function(data) {
            return [
              `<div class="qhealth__search__autocomplete__suggestion tt-suggestion tt-selectable">`,
                `<a href="${serviceFinderUrl}/${data.path}"><strong class="tt-highlight">${data.disp}</strong></a>`,
              `<div>`
            ].join('\n');
          },
          notFound: function() {
            return [
              `<h4 class="tt-category">Near ${currUserLocation}</h4>`,
              `<div class="qhealth__search__autocomplete__no-suggestions">`,
                `<em>No suggestions found</em>`,
              `<div>`
            ].join('\n');
          },
          footer: function() {
            return [
              `<div class="more-results">`,
                `<a href="${serviceSearchUrl}?query=${currQuery}&collection=${fbServiceLocationsCollection}" class="qhealth__btn qhealth__btn--primary qhealth__search__autocomplete__see-more"><span class="qhealth__search__submit-btn-text">See more</span> <span class="sr-only">results for your query within services</span></a>`,
              `<div>`
            ].join('\n');
          }
        },
      });
    };

    QH.servicesSearchForm = servicesSearchForm;

    document.addEventListener('DOMContentLoaded', QH.servicesSearchForm.init);
}());