(function () {
    'use strict';


    var sfImporter = {};
    const gatherContentOptions = {
        method: 'GET',
        headers: {
          Accept: 'application/vnd.gathercontent.v2+json',
          Authorization: 'Basic ZGlnaXRhbEBoZWFsdGgucWxkLmdvdi5hdTo2N2M0MzE0NC01MWExLTQ5YmItOGUyNi1lYTE3OTYxOGUwNDQ='
        }
    };
    var dsServiceLocationData;
    var dsServiceData;
    var dsFacilityData;

    /**
     * Init the Service Finder importer.
     * 
     * @memberof module:serviceImporter
     */
    sfImporter.init = function() {
        var $loader = $('.qhealth__sf_admin_importer');
        var $importForm = $('#sf-importer__form');
        var $importBtn = $('#sf-importer__import-btn');
        var $projectIdInput = $('#sf-importer__project-id');
        var $itemIdInput = $('#sf-importer__item-id');
        var $importLog = $('#sf-importer__log');

        var $importLogAdded = $('#sf-importer-tab--added');
        var $importLogNotFound = $('#sf-importer-tab--not-found');
        var $importLogAlreadyExists = $('#sf-importer-tab--already-exists');
        var $importLogOtherErrors = $('#sf-importer-tab--other-errors');
        var counter = ".sf-importer-tab--counter";

        // Init jQuery validation
        QH.forms.init();

        $importBtn.on('click', function(e) {
            e.preventDefault();
            $importForm.valid();

            if($importForm.valid()) {
                $loader.addClass('loading');
                $importLogAdded.empty();
                $("#sf-importer-tab-heading--added").find(counter).html('0');
                $importLogNotFound.empty();
                $("#sf-importer-tab-heading--not-found").find(counter).html('0');
                $importLogAlreadyExists.empty();
                $("#sf-importer-tab-heading--already-exists").find(counter).html('0');
                $importLogOtherErrors.empty();
                $("#sf-importer-tab-heading--other-errors").find(counter).html('0');
                
                if($projectIdInput.val() !== "") {
                    sfImporter.getProjectData($projectIdInput.val());
                } else if($itemIdInput.val() !== "") {
                    sfImporter.getItemData($itemIdInput.val());
                }

            } else {
                console.log('Not valid');
            }
        });
    };

    /**
     * Fetch project data from gathercontent
     * 
     * @memberof module:serviceImporter
     */
    sfImporter.getProjectData = function(id) {
        var $loader = $('.qhealth__sf_admin_importer');
        var $importLog = $('#sf-importer__log');
        var templateId = "2653852";
        var statusId = "2087817";

        fetch(`https://api.gathercontent.com/projects/${id}/items?template_id=${templateId}&status_id=${statusId}`, gatherContentOptions)
        .then(response => response.json())
        .then(response => {
            $("#sf-importer__form-errors").empty();
            sfImporter.getDatastoreData();
            sfImporter.getServiceLocationItems(response.data);
        }).then(response => {

            setTimeout(function() {
                $importLog.fadeIn("slow");
                $loader.removeClass('loading');
            }, 4000);
        })
        .catch(err => {
            $loader.removeClass('loading');
            $("#sf-importer__form-errors").html(`<p class="text-error">Project ID is invalid.</p>`);
        });
    }

    /**
     * Fetch item data from gathercontent
     * 
     * @memberof module:serviceImporter
    */
    sfImporter.getItemData = function(id) {
        var $loader = $('.qhealth__sf_admin_importer');

        fetch(`https://api.gathercontent.com/items/${id}`, gatherContentOptions)
        .then(response => response.json())
        .then(response => {
            sfImporter.checkIfImportable(response.data);
        })
        .catch(err => console.log(err));
    }

    /**
     * Get Service Locations, Facilities and Services from Datastore.
     * 
     * @memberof module:serviceImporter
    */
     sfImporter.getDatastoreData = function() {
        
        fetch(`${serviceFinderData.baseURL}/service-locations`)
        .then(response => response.json())
        .then(response => {
            dsServiceLocationData = response;

            return fetch(`${serviceFinderData.baseURL}/services`);
        })
        .then(response => response.json())
        .then(response => {
            dsServiceData = response;

            return fetch(`${serviceFinderData.baseURL}/facilities`);
        })
        .then(response => response.json())
        .then(response => {
            dsFacilityData = response;
        })
        .catch(err => console.error(err));
    }

    /**
     * Get service location from item data
     * 
     * @memberof module:serviceImporter
    */
     sfImporter.getServiceLocationItems = function(data) {
        var listData = data;
        
        for(var [i, item] of listData.entries()) {
            sfImporter.getItemData(item.id);
        }
    }

    /**
     * Import service location from item data
     * 
     * @memberof module:serviceImporter
    */
    sfImporter.importServiceLocation = function(serviceLocationData, serviceData, facilityData) {
        var $loader = $('.qhealth__sf_admin_importer');

        var serviceLocation = {
            "name": serviceLocationData.name,
            "web_path": QH.adminForm.kebabFormat(serviceLocationData.name),
            "service_id": serviceData.id,
            "facility_id": serviceData.id,
            "facility_level": "",
            "rsd_service_id": serviceLocationData.content["b2aca53d-235b-4979-9d7e-5dc83aa7ad3d"].replace(/<\/?[^>]+(>|$)/g, ""),
            "description": serviceLocationData.content["8499bc69-fd98-6d85-f9c3-a367c91c2c45"],
            "content": `${serviceLocationData.content["db0c6a46-8776-4ff6-a7f4-186d75fe6ac4"]}${serviceLocationData.content["37e88739-49de-b359-65fd-494b0e3ea8b3"]}`,
            "status": serviceLocationData.content["f6d2de6f-dbcd-8bf5-9376-1525e49c4b72"].label ? serviceLocationData.content["f6d2de6f-dbcd-8bf5-9376-1525e49c4b72"].label.toLowerCase() : "active",
            "building": serviceLocationData.content["4dbdb09d-129f-4d30-97d3-bbdbaef38665"].replace(/(<([^>]+)>)/gi, ""),
            "physical_address_street": facilityData.physical_address_street.replace(/(<([^>]+)>)/gi, ""),
            "physical_address_suburb": facilityData.physical_address_suburb.replace(/(<([^>]+)>)/gi, ""),
            "physical_address_postcode": facilityData.physical_address_postcode.replace(/(<([^>]+)>)/gi, ""),
            "location_details": facilityData.location_details,
            "latitude": facilityData.latitude,
            "longitude": facilityData.longitude,
            "contact_label": serviceLocationData.content["4d0e5c11-1a94-54c0-c39f-0508958fe787"].length > 0 ? serviceLocationData.content["4d0e5c11-1a94-54c0-c39f-0508958fe787"][0].label : "",
            "phone_number": facilityData.phone_number.replace(/(<([^>]+)>)/gi, ""),
            "secondary_contact_label": serviceLocationData.content["71e08586-af82-f1a3-2b69-4ed2807e4787"].length > 0 ? serviceLocationData.content["71e08586-af82-f1a3-2b69-4ed2807e4787"][0].label : "",
            "secondary_phone_number": serviceLocationData.content["4a30d493-063b-89b1-cabe-959a5bd780c4"],
            "fax_number": facilityData.fax_number,
            "email_address": facilityData.email_address,
            "opening_hours": serviceLocationData.content["06d55ae1-cf71-4c61-a041-022125ea1c9e"],
            "updatedBy": serviceFinderData.jwt.getPayload().fullName,
            "updatedDate": Date.now()
        };

        var facilityLevel = serviceLocationData.content["67c43144-51a1-49bb-8e26-ea179618e044"] ? serviceLocationData.content["67c43144-51a1-49bb-8e26-ea179618e044"].split(' - ')[0] : null;
        var streetAddress = serviceLocationData.content["ed428a02-5b5e-4e47-8ab3-09b8117799fb"];
        var suburb = serviceLocationData.content["152579e4-0fe0-4e54-a277-8f6b685aa018"];
        var postcode = serviceLocationData.content["6578def1-a5ea-1655-2e42-1310c776e7da"];
        var latitude = serviceLocationData.content["9d107c8c-2fda-3cee-3cce-5ab58fbc2a21"];
        var longitude = serviceLocationData.content["c0c648e8-f88f-fc39-26d8-d2ed820cf654"];
        var phoneNumber = serviceLocationData.content["637607df-c991-436c-aadd-0fc38d3281e4"];

        if(facilityLevel === "Major facility") {
            serviceLocation.facility_level = "major";
        } else if(facilityLevel = "Limited services") {
            serviceLocation.facility_level = "limited";
        } else {
            serviceLocation.facility_level = "";
        }

        if(streetAddress) {
            serviceLocation.physical_address_street = streetAddress.replace(/(<([^>]+)>)/gi, "");
        }

        if(suburb) {
            serviceLocation.physical_address_suburb = suburb.replace(/(<([^>]+)>)/gi, "");
        }

        if(postcode) {
            serviceLocation.physical_address_postcode = postcode.replace(/(<([^>]+)>)/gi, "");
        }

        if(latitude) {
            serviceLocation.latitude = latitude;
        }

        if(longitude) {
            serviceLocation.longitude = longitude;
        }

        if(phoneNumber) {
            serviceLocation.phone_number = phoneNumber.replace(/(<([^>]+)>)/gi, "");
        }

        serviceFinderData.jwt.getAuthToken();

        serviceFinderData.collection('service-locations').add(serviceLocation).then(response => {
            sfImporter.report(`<strong>${serviceLocationData.name} [id#${serviceLocationData.id}]</strong> was successfully imported into Service Locations. <a href="${sfAdminLinks.view}?collection=service-locations&document=${response.id}" target="_blank">View Service Location</a>.`, "Added", "success");
        }).catch((error) => {
            sfImporter.report(`<strong>${serviceLocationData.name} [id#${serviceLocationData.id}]</strong> failed to import because ${error}`, "Other errors", "error");
            $loader.removeClass('loading');
        });

    }

    /**
     * Build service location from item data
     * 
     * @memberof module:serviceImporter
    */
    sfImporter.checkIfImportable = function(data) {
        var serviceLocationData = data;
        var serviceLocationName = serviceLocationData.name;
        var serviceName = serviceLocationName.split(' - ')[0];
        var facilityName = serviceLocationName.split(' - ')[1];

        // Check if Service Location already exists in Datastore
        if(sfImporter.getItemFromDatastoreByName(serviceLocationName, dsServiceLocationData)) {
            sfImporter.report(`<strong>${serviceLocationName} [id#${serviceLocationData.id}]</strong> is already a Service Location.`, "Already exists", "info");
        } else {
            // Check if Service exists in Datastore
            if(sfImporter.getItemFromDatastoreByName(serviceName, dsServiceData)) {
                // Check if Facility exists in Datastore
                if(sfImporter.getItemFromDatastoreByName(facilityName, dsFacilityData)) {

                    // Service Location doesn't already exist and Service & Facility exists - import
                    sfImporter.importServiceLocation(serviceLocationData, sfImporter.getItemFromDatastoreByName(serviceName, dsServiceData), sfImporter.getItemFromDatastoreByName(facilityName, dsFacilityData));

                } else {
                    sfImporter.report(`Import for <strong>${serviceLocationName} [id#${serviceLocationData.id}]</strong> failed. <strong>${facilityName}</strong> doesn't exist in facilities.`, "Not found", "error");
                }
            } else {
                sfImporter.report(`Import for <strong>${serviceLocationName} [id#${serviceLocationData.id}]</strong> failed. <strong>${serviceName}</strong> doesn't exist in services.`, "Not found", "error");
            }
        }
    }

    /**
     * Gets item from Datastore collection by Name
     * 
     * @memberof module:serviceImporter
    */
    sfImporter.getItemFromDatastoreByName = function(needle, haystack) {
        // Check if item exists in array/object.
        return haystack.find(function(item) {
            return item.name.toLowerCase() === needle.toLowerCase();
        });
    }


    /**
     * Build out and display report for import
     * 
     * @memberof module:serviceImporter
    */
    sfImporter.report = function(output, outcome, type = "success") {
        var $importLog = $('#sf-importer__log');
        var $importLogAdded = $('#sf-importer-tab--added');
        var $importLogNotFound = $('#sf-importer-tab--not-found');
        var $importLogAlreadyExists = $('#sf-importer-tab--already-exists');
        var $importLogOtherErrors = $('#sf-importer-tab--other-errors');
        var counter = ".sf-importer-tab--counter";

        if(outcome === "Added") {
            $importLogAdded.append(`<p class="text-${type}">${output}</p>`);
            $("#sf-importer-tab-heading--added").find(counter).html(parseInt($("#sf-importer-tab-heading--added").find(counter).html(), 10) + 1);
        }

        if(outcome === "Not found") {
            $importLogNotFound.append(`<p class="text-${type}">${output}</p>`);
            $("#sf-importer-tab-heading--not-found").find(counter).html(parseInt($("#sf-importer-tab-heading--not-found").find(counter).html(), 10) + 1);
        }

        if(outcome === "Already exists") {
            $importLogAlreadyExists.append(`<p class="text-${type}">${output}</p>`);
            $("#sf-importer-tab-heading--already-exists").find(counter).html(parseInt($("#sf-importer-tab-heading--already-exists").find(counter).html(), 10) + 1);
        }

        if(outcome === "Other errors") {
            $importLogOtherErrors.append(`<p class="text-${type}">${output}</p>`);
            $("#sf-importer-tab-heading--other-errors").find(counter).html(parseInt($("#sf-importer-tab-heading--other-errors").find(counter).html(), 10) + 1);
        }
    }

    // Assign 
    QH.sfImporter = sfImporter;

    // Init Admin Form
    $(document).ready(function() {
        if ($('.qhealth__sf_admin_importer').length > 0) {
            // This is initiated in externals/dsComponentLoader.js
            //QH.sfImporter.init();
        }
    });
}());