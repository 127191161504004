import '.././../../externals/funnelback.autocompletion-2.6.0.js';

(function () {
    /**
     * The Enhanced Search
     * 
     * @module enhancedSearch
     */

     var enhancedSearch = {

        /**
         * Initialise the Enhanced Search listeners for keyboard navigation
         * 
         * @memberof module:enhancedSearch
         */
        'init': function () {

            window.Bloodhound = require('corejs-typeahead/dist/bloodhound.js');

            //init autocomplete
            setTimeout(function() {
                jQuery('#qhealth__header__search-query').autocompletion({
                    program   : 'https://qh-search.squiz.cloud/s/suggest.json',
                    datasets: {
                        organic: {
                        collection: 'qld-health-design-system-web-DEV',
                        profile : '_default',
                        format: 'extended',
                        alpha: '0.5',
                        show: '10',
                        sort: '0',
                        group: true
                        }
                    },
                    typeahead: {hint: true},
                    length: 3
                });
                if(document.querySelector('#search-sort')){
                    document.querySelector('#search-sort').onchange = function(e) {
                        window.location.href = e.target.options[e.target.selectedIndex].getAttribute('data-href');
                    }
                }
            }, 500);


            //on facet click
            if(document.querySelector('.qhealth__enhanced-search__facet-list')){
                var facetInputs = document.querySelectorAll('.qhealth__enhanced-search__facet-list .qhealth__enhanced-search__facet-input');

                for (var i = 0; i < facetInputs.length; i++) {
                    var state;

                    facetInputs[i].addEventListener('change', function(){
                        if (this.checked) {
                            state = "add";
                            updateFacet(this, state);

                          } else {
                            state = "remove";
                            updateFacet(this, state);
                          }

                    })
                }
            };

            if(document.querySelector('.qhealth__enhanced-search__filters-list')){
                updateFilters();
            };
        
        },

    };

    /**
     * IE8 compatible function for replacing classes on a DOM node
     *
     * @memberof module:enhancedSearch
     * @instance
     * @private
     * 
     * @param  {object} element      - The DOM element we want to query with
     * @param  {object} target       - The DOM element we want to toggle classes on
     * @param  {object} state        - The current state of the animation on the element
     */

     function updateFacet(element, state) {

        var facet = element;
        var facetUrl = facet.dataset.toggleurl;
        
        console.log(facetInputs);
        if(state === "add"){
            //add facet to url
            window.location.href = facetUrl;
        }

        if(state === "remove"){
            //remove facet to url
            window.location.href = facetUrl;
        }

    }

    /**
     * IE8 compatible function for replacing classes on a DOM node
     *
     * @memberof module:enhancedSearch
     * @instance
     * @private
     * 
     * @param  {object} element      - The DOM element we want to query with
     * @param  {object} target       - The DOM element we want to toggle classes on
     * @param  {object} state        - The current state of the animation on the element
     */

     function updateFilters(element, state) {
        var filterCloseButtons = document.querySelectorAll('.qhealth__enhanced-search__filters-list .qhealth__enhanced-search__filter-tag--close');
        var filterClearAll = document.querySelector('.qhealth__enhanced-search__filters-list .qhealth__enhanced-search__filter-tag--clear');
        
        
        for (var i = 0; i < filterCloseButtons.length; i++) {
            
            filterCloseButtons[i].addEventListener('click', event => {
                var $this = event.currentTarget;
                var filterUrl = $this.dataset.toggleurl;
                console.log(filterUrl + "click");
                $this.parentNode.remove();
                window.location.href = filterUrl;
              });
        };

        filterClearAll.addEventListener('click', event => {
            var $this = event.currentTarget;
            var clearAllUrl = $this.dataset.clearurl;

            $this.parentNode.remove();
            window.location.href = clearAllUrl;
          });
       

    }



    document.addEventListener('DOMContentLoaded', function () {
        enhancedSearch.init();
    });

    
}());
